import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../../hooks/layout-context";
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {Dropdown} from "react-bootstrap";
import {ToastsContext} from "../../../hooks/toasts-context";
import {generate} from "../../../lib/random-id-lib";
import Confirmation from "../../../components/layout/confirmation";
import {UserContext} from '../../../hooks/user-context';
import {OrderStatus} from '../../../types/order-status';
import useOrderStatus from '../../../hooks/use-order-status';
import StatusPreview from '../../../components/layout/status-preview';

export default function OrderStatusList() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<OrderStatus>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const {listing, forceDeleteOrderStatus} = useOrderStatus();
  const [selectedDelete, setSelectedDelete] = useState<OrderStatus | null>(null);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const {toasts, setToasts} = useContext(ToastsContext);
  const {user, isLoading} = useContext(UserContext);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = true;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {path: null, label: t("Statusy zleceń")},
    ];
    setLayout({...layout});
  }, []);

  React.useEffect(() => {
    if (configLoaded) {
      if (layout.topSearch !== search) {
        setSearch(layout.topSearch);
      }
    }
  }, [layout.topSearch]);

  React.useEffect(() => {
    const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
    if (searchControl !== undefined && searchControl !== null) {
      if (search !== undefined && search !== null && search.length > 0 && search !== 'undefined' && search !== 'null') {
        setTimeout(() => {
          searchControl.value = search;
          searchControl.setAttribute('value', search);
        });
      }
    }
  }, [search, layout.showSearch]);

  const columns: Array<any> = [
    {
      name: t("Kolejność"),
      selector: (row: any) => <span>{row.position + 1}</span>,
      right: false,
      sortable: true,
      key: "position",
      maxWidth: "100px",
    },
    {
      name: t("Nazwa"),
      selector: (row: any) => <StatusPreview
        title={row.title}
        colorBackground={row.colorBackground}
        colorFont={row.colorFont} />,
      right: false,
      sortable: true,
      key: "title",
    },
    {
      name: "",
      selector: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={"div"}>
              <Link to={`/settings/order-status/edit/${row.id}`}>{t("Edytuj")}</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDelete(row)}>{t("Usuń")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      right: true,
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage, sortField, sortDirection);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage, sortField, sortDirection);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null,
    sSearch: string | null = null
  ) => {
    try {
      localStorage.setItem('table_order_statuses', JSON.stringify({
        page, currentPerPage, sField, sDirection, search
      }));
      const result = await listing(page, currentPerPage, sSearch === undefined || sSearch === null ? search : sSearch, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem('table_order_statuses');
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            setSearch(tableConfig.search);
            const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
            if (searchControl !== undefined && searchControl !== null) {
              if (tableConfig.search !== undefined && tableConfig.search !== null && tableConfig.search.length > 0 && tableConfig.search !== 'undefined' && tableConfig.search !== 'null') {
                setTimeout(() => {
                  searchControl.value = tableConfig.search;
                  searchControl.setAttribute('value', tableConfig.search);
                });
              }
            }
            if (tableConfig.search === undefined || tableConfig.search === null || tableConfig.search.length === 0) {
              await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection, tableConfig.search);
            }
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage, sortField, sortDirection, search);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage, sortField, sortDirection, search);
      }
    })();
  }, [search]);

  const handleSort = async (column: any, direction: any) => {
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  const deleteRow = async (id: any) => {
    if (selectedDelete !== undefined && selectedDelete !== null) {
      try {
        await forceDeleteOrderStatus(selectedDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Status zlecenia usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć. Status zlecenia jest używany."),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage, sortField, sortDirection);
    }

    setSelectedDelete(null);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="header-title">{t('Statusy zleceń')}</h4>
              <div>
                <Link to={"/settings/order-status/sort"} className="btn btn-light mb-2 ms-3">
                  <i className="mdi mdi-sort me-2"></i>
                  {t("Zmień kolejność")}
                </Link>
                <Link to={"/settings/order-status/add"} className="btn btn-light mb-2 ms-3">
                  <i className="mdi mdi-plus-circle me-2"></i>
                  {t("Dodaj status zlecenia")}
                </Link>
              </div>
            </div>
            <div className="table-responsive">
              {showTable ? (
                <DataTable
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl statusów zleceń na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  paginationDefaultPage={defaultPage}
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedDelete !== null}
                onClose={() => setSelectedDelete(null)}
                onConfirm={() => deleteRow(selectedDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć status zlecenia?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
