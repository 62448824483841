import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions} from "../lib/request-helper";
import {ShowLoaderContext} from "./show-loader-context";
import {CarBrand} from "../types/car";
import {FormInputCarBrand} from "../types/forms";

export default function useAppConfig() {
  const {setShowLoader} = useContext(ShowLoaderContext);

  const getAppConfig = async (): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/app-config`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateAppConfig = async (inputData: FormInputCarBrand): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/app-config`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    getAppConfig,
    updateAppConfig,
  };
}
