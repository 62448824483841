import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {CarBrand, CarModel} from "../../types/car";
import useCarModel from "../../hooks/use-car-model";
import {FormInputCarModel} from "../../types/forms";
import useCarBrand from "../../hooks/use-car-brand";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

interface CarModelFormProps {
  carModel?: CarModel | null;
  id?: string | null;
}

export default function CarModelForm({id = null, carModel = null}: CarModelFormProps) {
  const {t} = useTranslation();
  const {createCarModel, updateCarModel} = useCarModel();
  const {listingSimpleCarBrands} = useCarBrand();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputCarModel>();
  const {toasts, setToasts} = useContext(ToastsContext);
  const [carBrands, setCarBrands] = useState<Array<CarBrand>>([]);
  const [carBrandsOptions, setCarBrandsOptions] = useState<Array<CarBrand>>([]);
  const [showView, setShowView] = useState<boolean>(false);
  const [selectedCarBrand, setSelectedCarBrand] = useState<CarBrand | null>(null);

  React.useEffect(() => {
    if (carModel !== undefined && carModel !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputCarModel);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputCarModel, carModel[k as keyof CarModel]);
      }
    }

    (async () => {
      try {
        const rows: Array<CarBrand> = await listingSimpleCarBrands();
        setCarBrands(rows);
        setCarBrandsOptions(rows);
        if (carModel !== undefined && carModel !== null) {
          setSelectedCarBrand(rows.find(it => it.id!! === carModel.carBrand!!)!);
        }
        setShowView(true);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    register("carBrand", {value: "", required: true});
  }, []);

  React.useEffect(() => {
    setValue("carBrand", carModel?.carBrand!!);
  }, [carBrands]);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updateCarModel(id, data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/car/model");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createCarModel(data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/car/model");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  const filterBy = () => true;

  const handleSearchCarBrand = async (query: string) => {
    const rows = carBrands.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarBrandsOptions(rows);
  };

  const changedCarBrand = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("carBrand", (e[0] as CarBrand).id!!);
      setSelectedCarBrand(e[0] as CarBrand);
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj model pojazdu" : "Dodaj model pojazdu")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Marka")}
                    </label>
                    {showView ? (
                      <AsyncTypeahead
                        filterBy={filterBy}
                        id="async-car-brands"
                        isLoading={false}
                        labelKey="title"
                        minLength={0}
                        selected={
                          // carModel !== undefined && carModel !== null
                          //   ? [carBrandsOptions.find((it) => it.id === carModel?.carBrand!!) as any]
                          //   : []
                          selectedCarBrand !== null ? [selectedCarBrand] : []
                        }
                        onSearch={handleSearchCarBrand}
                        onChange={(e) => changedCarBrand(e)}
                        options={carBrandsOptions}
                        placeholder={t("Wyszukaj markę")}
                        inputProps={{className: 'form-control-lg'}}
                        renderMenuItemChildren={(option: any) => (
                          <>
                            <span>{option.title}</span>
                          </>
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {errors && errors.carBrand !== undefined ? (
                      <span className={"error-message"}>{t(errors.carBrand?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Model")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings/car/model"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
