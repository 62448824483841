import React, {createRef, useState} from 'react';
import DatePicker from "react-datepicker";
import {useTranslation} from 'react-i18next';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import pl from "date-fns/locale/pl";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import usePartners from '../../../hooks/use-partners';
import moment from 'moment/moment';

registerLocale("pl", pl);

interface OrdersFiltersProps {
  inputFilters: any;
  onFiltersChanged: (params: any) => void;
}

export default function OrdersFilters({inputFilters = {}, onFiltersChanged}: OrdersFiltersProps) {
  const {t} = useTranslation();
  const {listingSimplePartners} = usePartners();
  const refSearchPartnerSource = createRef<any>();
  const refSearchPartnerDestination = createRef<any>();
  const [filters, setFilters] = useState<any>({});
  const [isLoadingPartnerSource, setIsLoadingPartnerSource] = useState<boolean>(false);
  const [isLoadingPartnerDestination, setIsLoadingPartnerDestination] = useState<boolean>(false);
  const [partnerSourceOptions, setPartnerSourceOptions] = useState<Array<any>>([])
  const [partnerDestinationOptions, setPartnerDestinationOptions] = useState<Array<any>>([])

  const filterBy = () => true;

  const handleFilter = (asExport = false) => {
    const data: any = {
      startDate: null,
      endDate: null,
      partnerSource: null,
      partnerDestination: null,
    };
    try {
      if (filters.startDate !== undefined && filters.startDate !== null) {
        data.startDate = moment(filters.startDate).format('YYYY-MM-DD');
      }
      if (filters.endDate !== undefined && filters.endDate !== null) {
        data.endDate = moment(filters.endDate).format('YYYY-MM-DD');
      }
      if (filters.partnerSource !== undefined && filters.partnerSource !== null) {
        data.partnerSource = filters.partnerSource.id;
      }
      if (filters.partnerDestination !== undefined && filters.partnerDestination !== null) {
        data.partnerDestination = filters.partnerDestination.id;
      }
    } catch (e: any) {
      console.log(e);
    }
    data.export = asExport ? 'on' : 'off';
    onFiltersChanged(data);
  }

  const setStartDate = (d: any) => {
    filters.startDate = d;
    setFilters({...filters});
  }

  const setEndDate = (d: any) => {
    filters.endDate = d;
    setFilters({...filters});
  }

  const clearFilters = () => {
    filters.startDate = null;
    filters.endDate = null;
    filters.partnerSource = null;
    refSearchPartnerSource.current.clear();
    filters.partnerDestination = null;
    refSearchPartnerDestination.current.clear();
    setFilters({...filters});
    handleFilter();
  }

  const handleSearchPartnerSource = async (query: string) => {
    setIsLoadingPartnerSource(true);

    try {
      const rows = await listingSimplePartners(query);
      setPartnerSourceOptions(rows);
    } catch (e: any) {
      console.log(e);
    }

    setIsLoadingPartnerSource(false);
  };

  const changedPartnerSource = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      filters.partnerSource = e[0];
      setFilters({...filters});
    }
  };

  const handleSearchPartnerDestination = async (query: string) => {
    setIsLoadingPartnerDestination(true);

    try {
      const rows = await listingSimplePartners(query);
      setPartnerDestinationOptions(rows);
    } catch (e: any) {
      console.log(e);
    }

    setIsLoadingPartnerDestination(false);
  };

  const changedPartnerDestination = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      filters.partnerDestination = e[0];
      setFilters({...filters});
    }
  };

  const exportData = () => {
    handleFilter(true);
  }

  return (
    <div className={'filters-box'}>
      <div className="row">
        <div className="col-sm-12 mb-4 d-flex align-items-center">
          <div className={'filter-col'}>
            <div className={"pr-label"}>{t("Data od")}</div>
            <DatePicker
              className="form-control form-control-sm input-date"
              selected={filters.startDate}
              onChange={(date: any) => {
                setStartDate(date);
              }}
              locale="pl"
              dateFormat="P"
            />
          </div>
          <div className={'filter-col'}>
            <div className={"pr-label"}>{t("Data do")}</div>
            <DatePicker
              className="form-control form-control-sm input-date"
              selected={filters.endDate}
              onChange={(date: any) => {
                setEndDate(date);
              }}
              locale="pl"
              dateFormat="P"
            />
          </div>
          <div className={'filter-col'}>
            <div className={"pr-label"}>{t("Oddział wysyłający")}</div>
            <AsyncTypeahead
              ref={refSearchPartnerSource}
              clearButton={true}
              filterBy={filterBy}
              id="async-partner-source"
              isLoading={isLoadingPartnerSource}
              labelKey="title"
              minLength={0}
              onSearch={handleSearchPartnerSource}
              onInputChange={(e: any) => {
                if (e === undefined || e === null || e.length === 0) {
                  filters.partnerSource = null;
                  refSearchPartnerSource.current.clear();
                }
              }}
              onChange={(e) => changedPartnerSource(e)}
              options={partnerSourceOptions}
              placeholder={t("Oddział wysyłający")}
              renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                <input
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                  type="text"
                  className={`rbt-input-main form-control rbt-input form-control-sm cars-search-input`}
                />
              )}
              renderMenuItemChildren={(option: any) => (
                <>
                      <span>
                        {option.title}, {option.city}, {option.street}
                      </span>
                </>
              )}
            />
          </div>
          <div className={'filter-col'}>
            <div className={"pr-label"}>{t("Oddział docelowy")}</div>
            <AsyncTypeahead
              ref={refSearchPartnerDestination}
              clearButton={true}
              filterBy={filterBy}
              id="async-partner-destination"
              isLoading={isLoadingPartnerDestination}
              labelKey="title"
              minLength={0}
              onSearch={handleSearchPartnerDestination}
              onInputChange={(e: any) => {
                if (e === undefined || e === null || e.length === 0) {
                  filters.partnerDestination = null;
                  refSearchPartnerDestination.current.clear();
                }
              }}
              onChange={(e) => changedPartnerDestination(e)}
              options={partnerDestinationOptions}
              placeholder={t("Oddział docelowy")}
              renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                <input
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                  type="text"
                  className={`rbt-input-main form-control rbt-input form-control-sm cars-search-input`}
                />
              )}
              renderMenuItemChildren={(option: any) => (
                <>
                      <span>
                        {option.title}, {option.city}, {option.street}
                      </span>
                </>
              )}
            />
          </div>
          <div>
            <div className={"pr-label"}>&nbsp;</div>
            <button
              type="button"
              className="btn btn-success mb-0 ms-2 btn-sm"
              onClick={() => handleFilter()}
            >
              {t("Filtruj")}
            </button>
            <button
              type="button"
              className="btn btn-light mb-0 ms-2 btn-sm"
              onClick={() => {
                clearFilters()
              }}
            >
              {t("Wyczyść")}
            </button>
            <button
              type="button"
              className="btn btn-primary mb-0 ms-2 btn-sm"
              onClick={() => {
                exportData()
              }}
            >
              {t("Eksportuj")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
