import React, {useContext, useState} from 'react';
import {DpdOrder} from '../../types/dpd';
import useOrders from '../../hooks/use-orders';
import {useTranslation} from 'react-i18next';
import {LayoutContext} from '../../hooks/layout-context';
import {Driver} from '../../types/driver';

export default function OrdersDpdList() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const {getOrdersDpdList} = useOrders();
  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<DpdOrder>>([]);

  React.useEffect(() => {
    layout.sideMenu = "orders";
    layout.showSearch = true;
    layout.breadcrumbs = [
      {path: '/orders', label: t("Zlecenia")},
      {path: null, label: t("Zamówieni kurierzy")}
    ];
    setLayout({...layout});
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const result = await getOrdersDpdList();
        if (result !== undefined && result !== null && result.length > 0) {
          setRows([...result]);
        }
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        setLoading(false);
      }
    })();
  }, []);

  const showDriversListing = (drivers: Array<Driver>) => {
    let result = '';

    if (drivers !== undefined && drivers !== null && drivers.length > 0) {
      result = drivers.map((z: any) => z.driverType !== undefined && z.driverType !== null && z.driverType.length > 0 ? z.driverType : z.driverNumber).join(', ');
    }

    return result;
  }

  return (
    !loading ? (
      <div>
        {rows.length === 0 ? (
          <div><h1>{t('Brak kurierów w najbliższych dniach')}</h1></div>
        ) : (
          <div>
            {rows.map((it, index) => (
              <div key={`DpdOrder${index}`} className={'mb-4'}>
                <div
                  className={`card mt-1 mb-0 shadow-none border${index % 2 === 0 ? ' card--lighter' : ' card--darker'}`}>
                  <div className="p-2">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className={'d-flex flex-row align-items-center'}>
                        <div className={'me-5'}>
                          <span>{t('Data')}:</span>
                          <span><b> {it.pickupDate}</b></span>
                        </div>
                        <div className={'me-5'}>
                          <span>{t('Paczki')}:</span>
                          <span><b> {it.packages?.length || 0}</b></span>
                        </div>
                        <div className={'me-5'}>
                          <span>{t('Oddział wysyłający')}:</span>
                          <span><b> {it.SourcePartner?.title}</b></span>
                        </div>
                      </div>

                      <div>
                        <a target={'_blank'} className={'d-flex flex-row align-items-center cursor-pointer'}
                           href={it.dpdUrl}>
                          <div>
                            <i className={'mdi mdi-open-in-new me-2 fs-6 text-primary'}></i>
                          </div>
                          <div>
                            <div>{t('Numer DPD')}:</div>
                            <div>{it.waybill}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {it.packages !== undefined && it.packages !== null && it.packages.length > 0 ? (
                  <div>
                    {it.packages.map((p, pIndex) => (
                      <div key={`DpdOrderPackages_${index}_${pIndex}`}>
                        <div
                          className={`card ms-5 mt-1 mb-0 shadow-none border${index % 2 === 0 ? ' card--lighter' : ' card--darker'}`}>
                          <div className={'p-2'}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                              <div className={'d-flex flex-row align-items-center'}>
                                <div className={'me-5'}>
                                  {t('Dane paczki')} <b>{pIndex + 1}</b>
                                </div>
                                <div className={'me-5'}>
                                  <span>{t('Rozmiary paczki')}:</span>
                                  <span> {p.sizeX}x{p.sizeY}x{p.sizeZ} cm</span>
                                </div>
                                <div className={'me-5'}>
                                  <span>{t('Waga paczki')}:</span>
                                  <span> {p.weight} kg</span>
                                </div>
                              </div>
                              <div>
                                <a className={'btn btn-primary mb-0 btn-sm me-3'} target={'_blank'}
                                   href={p.spedLabel}>
                                  {t('Pobierz etykietę')}
                                </a>
                              </div>
                            </div>
                            <hr className={'mt-3 mb-3'} />
                            <div className={'d-flex align-items-center'}>
                              <div className={'me-5'}>
                                {t('Dane zlecenia')}
                              </div>
                              <div className={'me-5'}>
                                <span>{t('Sterowniki')}:</span>
                                <span> {showDriversListing(p.drivers)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {t('Brak paczek')}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      <></>
    )
  )
}
