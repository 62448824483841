import React, {useState} from "react";
import {Order} from '../../types/order';
import {Driver, DriverAnswerFile} from '../../types/driver';
import {useTranslation} from 'react-i18next';
import StatusPreview from './status-preview';
import OrderViewHistory from './order-view-history';
import DeliveryMethod from './delivery-method';
import Dropzone from 'react-dropzone';

interface OrderFormProps {
  order?: Order | null;
  id?: string | null;
}

export default function OrderViewDetails({id = null, order = null}: OrderFormProps) {
  const {t} = useTranslation();
  const [drivers, setDrivers] = useState<Array<Driver>>([]);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
        setDrivers([...order.drivers]);
      }
    }
  }, []);

  const changeShowDriverQuestions = (index: number) => {
    drivers[index]!.showQuestions = !drivers[index]!.showQuestions;
    setDrivers([...drivers]);
  }

  const getAnswerFiles = (driverIndex: number, driverQuestionId: string) => {
    let files: Array<DriverAnswerFile> = [];

    try {
      if (drivers[driverIndex]!.answers !== undefined && drivers[driverIndex]!.answers !== null && drivers[driverIndex]!.answers!.length > 0) {
        if (drivers[driverIndex]!.answers!.findIndex(it => it.driverQuestion === driverQuestionId!) >= 0) {
          files = drivers[driverIndex]!.answers!.find(it => it.driverQuestion === driverQuestionId)!.files!;
          if (files === undefined || files === null) {
            files = [];
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }

    return files;
  }

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className={'d-flex align-items-center'}>
                  <StatusPreview
                    title={order!.CurrentStatus!.title}
                    colorBackground={order!.CurrentStatus!.colorBackground}
                    colorFont={order!.CurrentStatus!.colorFont}
                  />
                  <h4 className="header-title ms-3 mb-0">
                    <span>{t('Zlecenie numer:')}</span>
                    <span> {order?.orderNumber}</span>
                  </h4>
                </div>
                <div>
                  <DeliveryMethod deliveryMethod={order?.deliveryMethod!} />
                </div>
              </div>
              <div className={'mb-3'}>
                <b>{t('Oddział wysyłający')}:</b> {order!.PartnerSource!.title} ({order!.PartnerSource?.city}, {order!.PartnerSource?.street})
              </div>
              <div className={'mb-3'}>
                <b>{t('Oddział docelowy')}:</b> {order!.PartnerDestination!.title} ({order!.PartnerDestination?.city}, {order!.PartnerDestination?.street})
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-4">
                  <div className="dropzone-previews mt-3">
                    {drivers.map((d, index) => (
                      <div className={`card mt-1 mb-0 shadow-none border${d.toDelete ? ' disabled' : ''}`}
                           key={`DriverBeforeUpload${index}`}>
                        <div className="p-2">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="avatar-sm">
                              <span className="avatar-title rounded">
                                <i className="mdi mdi-flash-outline font-16"></i>
                              </span>
                              </div>
                            </div>
                            <div className="col ps-0">
                              <a className="file_name text-muted fw-bold" data-dz-name="">
                                {d.driverNumber}
                                {d.driverCode !== undefined && d.driverCode !== null && d.driverCode.length > 0 ? `(${d.driverCode})` : ''}
                              </a>
                              <p className="mb-0 file_name" data-dz-size="">
                                <strong>{d.CarBrand?.title} {d.CarModel?.title}</strong>
                              </p>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <button type={'button'} onClick={() => changeShowDriverQuestions(index)}
                                      className={'btn btn-outline-primary btn-lg me-3 text-no-wrap'}>
                                {!d.showQuestions ? (
                                  <i className={'mdi mdi-eye'}></i>
                                ) : (
                                  <i className={'mdi mdi-eye-off'}></i>
                                )}
                                <span className={'ms-2'}>
                                    {!d.showQuestions ? t('Pokaż pytania') : t('Zamknij pytania')}
                                  </span>
                              </button>
                              <StatusPreview
                                title={d.DriverStatus!.title}
                                colorBackground={d.DriverStatus!.colorBackground}
                                colorFont={d.DriverStatus!.colorFont} />
                            </div>
                          </div>
                          <div className={d.showQuestions ? 'show' : 'hidden'}>
                            <hr />
                            <div
                              className={'text-primary fw-bold mb-3'}>{t('Odpowiedzi na pytania są wymagane.')}</div>
                            <div className="row">
                              {d.driverQuestions!.map((q, qIndex) => (
                                <div className="col-12 col-md-6 mb-4" key={`DriverQuestion-${qIndex}`}>
                                  <label htmlFor={`question${qIndex + 1}`} className="form-label">{q.title}</label>
                                  {q.questionType === 'text' ? (
                                    <textarea
                                      className={`form-control`}
                                      rows={6}
                                      id={`driver_${index}_${d.id}_question_${q.id!}`}
                                      readOnly={true}
                                      defaultValue={d.answers!.length > 0 && d.answers!?.findIndex(it => it.driverQuestion === q.id!) >= 0 ? d.answers!?.find(it => it.driverQuestion === q.id!)!.content : ''}></textarea>
                                  ) : (
                                    q.questionType === 'upload' ? (
                                      <>
                                        <div className="dropzone-previews mt-3">
                                          {getAnswerFiles(index, q.id!).length === 0 ? (
                                            <div className={'text-danger'}>
                                              <span>{t('Brak plików. Nie zostały dodane.')}</span>
                                            </div>
                                          ) : <></>}
                                          {getAnswerFiles(index, q.id!).map((file, fileIndex) => (
                                            <div
                                              className={`card mt-1 mb-0 shadow-none border${file.toDelete ? ' disabled' : ''}`}
                                              key={`QuestionAnswerFile${index}_${qIndex}_${fileIndex}`}>
                                              <div className="p-2">
                                                <div className="row align-items-center">
                                                  <div className="col-auto">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded">
                                                          <i className="mdi mdi-file font-16"></i>
                                                        </span>
                                                    </div>
                                                  </div>
                                                  <div className="col ps-0">
                                                    <a className="file_name text-muted fw-bold" data-dz-name="">
                                                      {file.originalFileName}
                                                    </a>
                                                    <p className="mb-0 file_name" data-dz-size="">
                                                      <strong>{(file.fileSize / 1024 / 1024).toFixed(2)}</strong>MB
                                                    </p>
                                                  </div>
                                                  <div className="col-auto">
                                                    {file.path !== undefined && file.path !== null && file.path.length > 0 ? (
                                                      <a className={'btn btn-outline-primary ms-3'}
                                                         onClick={(e: any) => {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                           const link: any = document.createElement("a");
                                                           link.target = '_blank';
                                                           link.download = file.originalFileName;
                                                           link.href = file.path;
                                                           document.body.appendChild(link);
                                                           link.click();
                                                           document.body.removeChild(link);
                                                         }}>
                                                        <i className="mdi mdi-download font-16 me-2"></i>
                                                        <span>{t('Pobierz')}</span>
                                                      </a>
                                                    ) : <></>}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="title" className="form-label">
                      {t("Dodatkowe informacje do zlecenia")}
                    </label>
                    <textarea
                      className={"form-control form-control-lg"}
                      id="info"
                      rows={6}
                      readOnly={true}
                      defaultValue={order?.info}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderViewHistory history={order?.orderHistory!} />
    </>
  );
}
