import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FormInputDriver} from "../../types/forms";
import {useNavigate} from "react-router-dom";
import useDrivers from "../../hooks/use-drivers";
import {generate} from "../../lib/random-id-lib";
import {ToastsContext} from "../../hooks/toasts-context";
import {Driver, DriverAnswer, DriverAnswerFile, DriverFile} from '../../types/driver';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {CarBrand, CarModel, SocketDriverMessage} from "../../types/car";
import useCarBrand from '../../hooks/use-car-brand';
import useCarModel from '../../hooks/use-car-model';
import moment from 'moment';
import {DriverQuestion} from '../../types/driver-question';
import useDriverQuestion from '../../hooks/use-driver-question';
import Dropzone from 'react-dropzone';
import {getHeaderOptions} from '../../lib/request-helper';
import {socket} from '../../hooks/socket-context';
import axios from 'axios';
import {API_URL} from '../../config';
import {AppConfig} from '../../types/app-config';
import useAppConfig from '../../hooks/use-app-config';

interface DriverFormProps {
  driver?: Driver | null;
  answersInput?: Array<DriverAnswer>;
  id?: string | null;
  asModal?: boolean;
  onModalClose?: any;
}

export default function DriverForm({
                                     id = null,
                                     driver = null,
                                     answersInput = [],
                                     asModal = false,
                                     onModalClose = null
                                   }: DriverFormProps) {
  const {t} = useTranslation();
  const {
    getById,
    createDriver,
    updateDriver,
    updateDriverAnswers,
    forceDeleteDriverFile,
  } = useDrivers();
  const {listingSimpleCarBrands} = useCarBrand();
  const {listingSimpleCarModels} = useCarModel();
  const navigate = useNavigate();
  const {getAppConfig} = useAppConfig();
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
  const [answers, setAnswers] = useState<Array<DriverAnswer>>([]);
  const [driverFiles, setDriverFiles] = useState<Array<DriverFile>>([]);
  const [carBrands, setCarBrands] = useState<Array<CarBrand>>([]);
  const [carBrandsOptions, setCarBrandsOptions] = useState<Array<CarBrand>>([]);
  const [carModels, setCarModels] = useState<Array<CarModel>>([]);
  const [carModelsOptions, setCarModelsOptions] = useState<Array<CarModel>>([]);
  const [filteredCarModels, setFilteredCarModels] = useState<Array<CarModel>>([]);
  const [filesBeforeUpload, setFilesBeforeUpload] = useState<Array<any>>([]);
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputDriver>();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    register("carBrand", {value: "", required: true});
    register("carModel", {value: "", required: true});

    if (driver !== undefined && driver !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputDriver);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputDriver, driver[k as keyof Driver]);
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const cfg = await getAppConfig();
        setAppConfig(cfg);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarBrands();
        setCarBrands(rows);
        setCarBrandsOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carBrand", driver?.carBrand!!);
  }, [carBrands]);

  React.useEffect(() => {
    setAnswers([...answersInput]);
  }, []);

  React.useEffect(() => {
    if (driver !== undefined && driver !== null) {
      if (driver.driverFiles !== undefined && driver.driverFiles !== null) {
        setDriverFiles([...driver.driverFiles]);
      } else {
        setDriverFiles([]);
      }
    } else {
      setDriverFiles([]);
    }
  }, [driver]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarModels();
        setCarModels(rows);
        setCarModelsOptions([]);

        if (driver !== undefined && driver !== null) {
          const newValue: string = driver.CarBrand!!.id!!;
          const res = rows.filter((it: any) => it.carBrand === newValue);
          setFilteredCarModels([...res]);
          setCarModelsOptions([...res]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carModel", driver?.carModel!!);
  }, [carModels]);

  React.useEffect(() => {
    if (driver !== undefined && driver !== null) {
      setFilteredCarModels([...carModels.filter((it) => it.carBrand === driver.carBrand!!)]);
      setValue("carModel", driver?.carModel!!);
    }
  }, [carBrands, carModels]);

  const changedCarModel = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("carModel", (e[0] as CarModel).id!!);
    }
  };

  const changedCarBrand = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      const newValue: string = (e[0] as CarBrand).id!!;
      setValue("carBrand", newValue);
      const rows = carModels.filter((it) => it.carBrand === newValue);
      setValue("carModel", "");
      setFilteredCarModels([...rows]);
      setCarModelsOptions([...rows]);
    }
  };

  const handleDeleteFileUploaded = async () => {
    for (let i = 0; i < driverFiles.length; i++) {
      if (driverFiles[i].toDelete) {
        try {
          await forceDeleteDriverFile(driverFiles[i].driver, driverFiles[i].id!);
        } catch (e: any) {
          console.log(e);
        }
      }
    }
  }

  const handleFileUploads = async (driverId: string) => {
    if (filesBeforeUpload !== undefined && filesBeforeUpload !== null && filesBeforeUpload.length > 0) {
      const filesCounter = filesBeforeUpload.length;
      for (let i = 0; i < filesCounter; i++) {
        await uploadSingleFile(driverId, i, filesCounter);
      }
    }
  };

  const uploadSingleFile = async (driverId: string, index: number, filesCounter: number) => {
    const headers: any = getHeaderOptions();
    headers.onUploadProgress = (progressEvent: any) => {
      let value = (progressEvent.loaded * 100) / progressEvent.total / filesCounter + (100 / filesCounter) * index;

      if (index + 1 === filesCounter) {
        if (progressEvent.loaded === progressEvent.total) {
          value = 100;
        }
      }

      const content: SocketDriverMessage = {
        driver: driverId,
        progress: value,
        room: "all",
      };
      socket.emit("message", content);
    };

    const formData = new FormData();
    formData.set("file", filesBeforeUpload[index], filesBeforeUpload[index].name);
    await axios.post(`${API_URL}/driver/${driverId}/upload`, formData, headers);
  };

  const handleLoginAndReturn = async (data: any) => {
    handleSubmitData(data, true);
  }

  const handleLogin = async (data: any) => {
    handleSubmitData(data, false);
  };

  const handleSubmitData = async (data: any, returnResult = false) => {
    if (id !== undefined && id !== null) {
      try {
        await updateDriver(id, data);
        await handleFileUploads(id);
        await handleDeleteFileUploaded();
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/drivers");
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result: any = await createDriver(data);
        await handleFileUploads(result.driver.id);
        await handleDeleteFileUploaded();
        toasts.push({
          title: t("Sukces"),
          content: t("Dane zapisane"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
        if (!asModal) {
          navigate("/drivers");
        } else {
          if (onModalClose !== undefined && onModalClose !== null) {
            const driverResponse = await getById(result.driver.id!);
            onModalClose(returnResult ? driverResponse.driver : null);
          }
        }
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  }

  const cancelFileUpload = (index: number) => {
    filesBeforeUpload.splice(index, 1);
    setFilesBeforeUpload([...filesBeforeUpload]);
  };

  const markForDeleteFile = (index: number) => {
    driverFiles[index].toDelete = true;
    setDriverFiles([...driverFiles]);
  };

  const filterBy = () => true;

  const handleSearchCarBrand = async (query: string) => {
    const rows = carBrands.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarBrandsOptions(rows);
  };

  const handleSearchCarModels = async (query: string) => {
    const rows = filteredCarModels.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarModelsOptions(rows);
  };

  const onDropFilesBeforeUpload = (acceptedFiles: any) => {
    setFilesBeforeUpload([
      ...filesBeforeUpload.concat(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploading: true,
            currentUploading: false,
          })
        )
      ),
    ]);
  }

  return (
    appConfig !== undefined && appConfig !== null ? (
      <>
        <div className="row mb-5">
          <div className="col-12">
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className={`${!asModal ? 'card shadow' : ''}`}>
                <div className="card-body">
                  {!asModal ? (
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="header-title">
                        {t(id !== undefined && id !== null ? "Edytuj sterownik" : "Dodaj sterownik")}
                      </h4>
                    </div>
                  ) : <></>}
                  <div className="row"></div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Nazwa sterownika")}</label>
                        <input
                          className={"form-control form-control-lg" + (errors && errors.driverType !== undefined ? " has-error" : "")}
                          id="driverType"
                          {...register("driverType", {required: true})}
                          defaultValue={driver !== undefined && driver !== null ? driver.driverType : ''}
                        />
                        {errors && errors.driverType !== undefined ? (
                          <span className={"error-message"}>{t(errors.driverType?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label htmlFor="title" className="form-label">
                          {t("Numer sterownika")}
                        </label>
                        <input
                          className={"form-control form-control-lg" + (errors && errors.driverNumber !== undefined ? " has-error" : "")}
                          id="driverNumber"
                          {...register("driverNumber", {required: true})}
                          defaultValue={driver !== undefined && driver !== null ? driver.driverNumber : ''}
                        />
                        {errors && errors.driverNumber !== undefined ? (
                          <span className={"error-message"}>{t(errors.driverNumber?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Marka pojazdu")}</label>
                        <AsyncTypeahead
                          defaultInputValue={driver !== undefined && driver !== null ? driver.CarBrand?.title : ""}
                          filterBy={filterBy}
                          id="async-car-brand"
                          isLoading={false}
                          labelKey="title"
                          minLength={0}
                          onSearch={handleSearchCarBrand}
                          onChange={(e) => changedCarBrand(e)}
                          options={carBrandsOptions}
                          placeholder={t("Wybierz markę")}
                          inputProps={{className: 'form-control-lg' + (errors && errors.carBrand !== undefined ? " has-error" : "")}}
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option.title}</span>
                            </>
                          )}
                        />
                        {errors && errors.carBrand !== undefined ? (
                          <span className={"error-message"}>{t(errors.carBrand?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Model pojazdu")}</label>
                        <AsyncTypeahead
                          defaultInputValue={driver !== undefined && driver !== null ? driver.CarModel?.title : ""}
                          filterBy={filterBy}
                          id="async-car-model"
                          isLoading={false}
                          labelKey="title"
                          minLength={0}
                          onSearch={handleSearchCarModels}
                          onChange={(e) => changedCarModel(e)}
                          options={carModelsOptions}
                          placeholder={t("Wybierz model")}
                          inputProps={{className: 'form-control-lg' + (errors && errors.carModel !== undefined ? " has-error" : "")}}
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option.title}</span>
                            </>
                          )}
                        />
                        {errors && errors.carModel !== undefined ? (
                          <span className={"error-message"}>{t(errors.carModel?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Rok")}</label>
                        <input
                          className={"form-control form-control-lg" + (errors && errors.year !== undefined ? " has-error" : "")}
                          id="year"
                          {...register("year", {required: false})}
                          defaultValue={driver !== undefined && driver !== null ? driver.year as string : ''}
                        />
                        {errors && errors.year !== undefined ? (
                          <span className={"error-message"}>{t(errors.year?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label htmlFor="title" className="form-label">
                          {t("Kod wewnętrzny")}
                        </label>
                        <input
                          className={"form-control form-control-lg" + (errors && errors.driverCode !== undefined ? " has-error" : "")}
                          id="driverCode"
                          {...register("driverCode", {required: false})}
                          defaultValue={driver !== undefined && driver !== null ? driver.driverCode : ''}
                        />
                        {errors && errors.driverCode !== undefined ? (
                          <span className={"error-message"}>{t(errors.driverCode?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {appConfig.showDriverFileUpload ? (
                    <div className="row">
                      <div className="col-12 mb-4">
                        <span className="form-label d-block">{t("Dodaj plik")}</span>
                        <Dropzone onDrop={onDropFilesBeforeUpload} disabled={false}>
                          {({getRootProps, getInputProps}) => (
                            <label {...getRootProps({className: "dropzone"})} className="dropzone">
                              <div className="dz-message">
                                <i className="icon mdi mdi-cloud-upload-outline"></i>
                                <h4 className="text">{t("Upuść plik lub kliknij żeby wgrać")}</h4>
                              </div>
                              <input className="d-none" type="file" id="formFile" />
                            </label>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3">
                          {driver !== undefined && driver !== null && driverFiles.length > 0 ? (
                            driverFiles.map((file: any, index: number) => (
                              <div className={`card mt-1 mb-0 shadow-none border${file.toDelete ? ' disabled' : ''}`}
                                   key={`DriverFileUploaded${index}`}>
                                <div className="p-2">
                                  <div className="row align-items-center">
                                    <div className="col-auto">
                                      <div className="avatar-sm">
                                <span className="avatar-title rounded">
                                  <i className="mdi mdi-file font-16"></i>
                                </span>
                                      </div>
                                    </div>
                                    <div className="col ps-0">
                                      <a className="file_name text-muted fw-bold" data-dz-name="">
                                        {file.originalFileName}
                                      </a>
                                      <p className="mb-0 file_name" data-dz-size="">
                                        <strong>{(file.fileSize / 1024 / 1024).toFixed(2)}</strong>MB
                                      </p>
                                    </div>
                                    <div className="col-auto">
                                      {file.path !== undefined && file.path !== null && file.path.length > 0 ? (
                                        <a className={'btn btn-outline-primary ms-3'} target={'_blank'}
                                           href={file.path}>
                                          <i className="mdi mdi-download font-16 me-2"></i>
                                          <span>{t('Pobierz')}</span>
                                        </a>
                                      ) : <></>}
                                      {!file.toDelete ? (
                                        <a
                                          onClick={() => markForDeleteFile(index)}
                                          className="btn btn-link btn-lg text-muted"
                                          data-dz-remove=""
                                        >
                                          <i className="mdi mdi-close"></i>
                                        </a>
                                      ) : <></>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                          {filesBeforeUpload.map((file: any, index: number) => (
                            <div className="card mt-1 mb-0 shadow-none border" key={`FileBeforeUpload${index}`}>
                              <div className="p-2">
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <div className="avatar-sm">
                                <span className="avatar-title rounded">
                                  <i className="mdi mdi-file font-16"></i>
                                </span>
                                    </div>
                                  </div>
                                  <div className="col ps-0">
                                    <a className="file_name text-muted fw-bold" data-dz-name="">
                                      {file.path}
                                    </a>
                                    <p className="mb-0 file_name" data-dz-size="">
                                      <strong>{(file.size / 1024 / 1024).toFixed(2)}</strong>MB
                                    </p>
                                  </div>
                                  <div className="col-auto">
                                    <a
                                      onClick={() => cancelFileUpload(index)}
                                      className="btn btn-link btn-lg text-muted"
                                      data-dz-remove=""
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                </div>
                <div className="card-footer p-4 text-muted">
                  <div className="row">
                    <div className="col-12 text-center">
                      {asModal ? (
                        <>
                          <button value={'save'}
                                  className="btn btn-lg btn-outline-primary me-3">{t("Zapisz sterownik do bazy sterowników")}</button>
                          <button value={'add'}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(handleLoginAndReturn)();
                                  }}
                                  className="btn btn-lg btn-outline-success ms-3">{t("Dodaj sterownik do bazy sterowników i zlecenia")}</button>
                        </>
                      ) : (
                        id !== undefined && id !== null ? (
                          <button className="btn btn-lg btn-outline-primary ms-3">{t("Zapisz sterownik")}</button>
                        ) : (
                          <button className="btn btn-lg btn-outline-primary ms-3">{t("Zapisz sterownik")}</button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    ) : <></>
  );
}
