import React, {useCallback, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../../hooks/layout-context";
import {Link} from "react-router-dom";
import {ToastsContext} from "../../../hooks/toasts-context";
import {OrderStatus} from '../../../types/order-status';
import useOrderStatus from '../../../hooks/use-order-status';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import SortableOrderStatus from '../../../components/sortable/sortable-order-status';
import update from 'immutability-helper';
import {generate} from '../../../lib/random-id-lib';
import {SortableUpdate} from '../../../types/sortable';

export default function OrderStatusSort() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<OrderStatus>>([]);
  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const {listingSimpleOrderStatus, updateOrderStatusPosition} = useOrderStatus();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {path: '/settings/order-status', label: t("Statusy zleceń")},
      {path: null, label: t("Zmień kolejność")},
    ];
    setLayout({...layout});

    (async () => {
      await loadListing();
    })();
  }, []);

  const loadListing = async () => {
    try {
      const result = await listingSimpleOrderStatus();
      setRows(result);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setRows((prevItems: OrderStatus[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex] as OrderStatus],
        ],
      }),
    )
  }, [])

  const renderItem = useCallback(
    (item: { id: string; title: string }, index: number) => {
      return (
        <SortableOrderStatus
          key={item.id}
          index={index}
          id={item.id}
          title={item.title}
          moveItem={moveItem}
        />
      )
    },
    [],
  )

  const savePosition = async () => {
    try {
      const items: Array<SortableUpdate> = [];
      rows.forEach((row, index) => {
        items.push({id: row.id!, position: index});
      });
      await updateOrderStatusPosition(items);
      toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
      setToasts([...toasts]);
    } catch (e: any) {
      console.log(e);
      toasts.push({
        title: t("Błąd"),
        content: t("Nie udało się zapisać"),
        show: true,
        id: generate(32),
        type: "danger",
      });
      setToasts([...toasts]);
    }
  }

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="header-title">{t('Zmień kolejność statusów')}</h4>
              <div>
                <Link to={"/settings/order-status"} className="btn btn-light mb-2 ms-3">
                  <i className="mdi mdi-chevron-left me-2"></i>
                  {t("Wróć do listy")}
                </Link>
                <a className="btn btn-light mb-2 ms-3" onClick={savePosition}>
                  <i className="mdi mdi-content-save me-2"></i>
                  {t("Zapisz kolejność")}
                </a>
              </div>
            </div>
            <div className="table-responsive">
              {showTable ? (
                <>
                  <DndProvider backend={HTML5Backend}>
                    {rows.map((it, i) => renderItem({id: it.id!, title: it.title}, i))}
                  </DndProvider>
                  <div className="d-flex justify-content-between align-items-center mt-5">
                    <div></div>
                    <a className="btn btn-light mb-2 ms-3" onClick={savePosition}>
                      <i className="mdi mdi-content-save me-2"></i>
                      {t("Zapisz kolejność")}
                    </a>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
