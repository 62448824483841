import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {DriverQuestion} from '../../types/driver-question';
import useDriverQuestion from '../../hooks/use-driver-question';
import {FormInputDriverQuestion} from '../../types/forms';

interface DriverQuestionFormProps {
  driverQuestion?: DriverQuestion | null;
  id?: string | null;
}

export default function DriverQuestionForm({id = null, driverQuestion = null}: DriverQuestionFormProps) {
  const {t} = useTranslation();
  const {createDriverQuestion, updateDriverQuestion} = useDriverQuestion();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputDriverQuestion>();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    if (driverQuestion !== undefined && driverQuestion !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputDriverQuestion);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputDriverQuestion, driverQuestion[k as keyof DriverQuestion]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updateDriverQuestion(id, data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/driver-questions");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createDriverQuestion(data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/driver-questions");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj pytanie do sterownika" : "Dodaj pytanie do sterownika")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Nazwa")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="questionType" className="form-label">
                      {t("Typ")}
                    </label>
                    <select
                      className={"form-control form-control-lg" + (errors && errors.questionType !== undefined ? " has-error" : "")}
                      id="questionType"
                      {...register("questionType", {required: true})}
                      defaultValue={""}
                    >
                      <option value={'text'}>{t('Tekst')}</option>
                      <option value={'upload'}>{t('Wgranie plików')}</option>
                    </select>
                    {errors && errors.questionType !== undefined ? (
                      <span className={"error-message"}>{t(errors.questionType?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings/driver-questions"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
