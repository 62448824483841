import React, {useRef} from 'react';
import {useDrag, useDrop, XYCoord} from 'react-dnd';

export interface SortableDriverQuestionProps {
  index: number;
  id: string;
  title: string;
  questionType: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number;
  id: string;
  title: string;
  questionType: string;
}

const style = {
  marginBottom: '1rem',
  cursor: 'move',
}

export default function SortableDriverQuestion({
                                                 id,
                                                 index,
                                                 title,
                                                 questionType,
                                                 moveItem
                                               }: SortableDriverQuestionProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: any }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: 'card',
    item: () => {
      return {id, index}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} style={{...style, opacity}} data-handler-id={handlerId}>
      <div className="card shadow">
        <div className="card-body">
          <div className={'d-flex align-items-center'}>
            <span>{title}</span>
            <span style={{
              marginLeft: 15,
              fontSize: 12,
              color: 'rgb(128, 128, 128)'
            }}>({questionType === 'upload' ? 'Wgrywanie plików' : 'Tekst'})</span>
          </div>
        </div>
      </div>
    </div>
  )
}
