import React, {useCallback, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FormInputOrder} from "../../types/forms";
import {Link, useNavigate} from "react-router-dom";
import {generate} from "../../lib/random-id-lib";
import {ToastsContext} from "../../hooks/toasts-context";
import {Order} from '../../types/order';
import useOrders from '../../hooks/use-orders';
import {Button, Modal} from 'react-bootstrap';
import DriverForm from './driver-form';
import {Driver, DriverAnswerFile} from '../../types/driver';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import useDrivers from '../../hooks/use-drivers';
import {DriverStatus} from '../../types/driver-status';
import useDriverStatus from '../../hooks/use-driver-status';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import SortableOrderDriverStatus from '../sortable/sortable-order-driver';
import update from 'immutability-helper';
import {OrderStatus} from '../../types/order-status';
import useOrderStatus from '../../hooks/use-order-status';
import {Partner} from '../../types/partner';
import {DriverQuestion} from '../../types/driver-question';
import useDriverQuestion from '../../hooks/use-driver-question';
import Dropzone from 'react-dropzone';
import {getHeaderOptions} from '../../lib/request-helper';
import axios from 'axios';
import {API_URL} from '../../config';

interface OrderFormProps {
  order?: Order | null;
  id?: string | null;
}

export default function OrderForm({id = null, order = null}: OrderFormProps) {
  const {t} = useTranslation();
  const {createOrder, updateOrder, createOrderNumber} = useOrders();
  const {listingSimpleDriverQuestion} = useDriverQuestion();
  const {listingSimpleOrderStatus} = useOrderStatus();
  const {listingSimpleDriverStatus} = useDriverStatus();
  const {listing, forceDeleteDriverAnswerFile} = useDrivers();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputOrder>();
  const {toasts, setToasts} = useContext(ToastsContext);
  const [showModalAddDriver, setShowModalAddDriver] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [drivers, setDrivers] = useState<Array<Driver>>([]);
  const [driversOptions, setDriversOptions] = useState<Array<Driver>>([]);
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(true);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [driverStatuses, setDriverStatuses] = useState<Array<DriverStatus>>([]);
  const [orderStatuses, setOrderStatuses] = useState<Array<OrderStatus>>([]);
  const [orderStatusesOptions, setOrderStatusesOptions] = useState<Array<OrderStatus>>([]);
  const [driverQuestions, setDriverQuestions] = useState<Array<DriverQuestion>>([]);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputOrder);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputOrder, order[k as keyof Order]);
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleDriverStatus();
        setDriverStatuses([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleDriverQuestion();
        setDriverQuestions(rows);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleOrderStatus();
        setOrderStatuses([...rows]);
        setOrderStatusesOptions([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (id !== undefined && id !== null) {
      if (order !== undefined && order !== null) {
        setOrderNumber(order.orderNumber);
      }
    } else {
      (async () => {
        try {
          const result: any = await createOrderNumber();
          if (result.orderNumber !== undefined && result.orderNumber !== null) {
            setOrderNumber(result.orderNumber);
          }
        } catch (e: any) {
          console.log(e);
        }
      })();
    }
  }, []);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
        for (let i = 0; i < order.drivers.length; i++) {
          try {
            if (order.drivers[i].driverStatus !== undefined && order.drivers[i].driverStatus !== null) {
              (document.querySelector(`#DriverStatus_${i}_${order.drivers[i].id!}`) as HTMLSelectElement).value = order.drivers[i].driverStatus!;
            }
          } catch (e: any) {
            console.log(e);
          }
        }
      }
    }
  }, [driverStatuses]);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
        for (let i = 0; i < order.drivers.length; i++) {
          order.drivers[i].toUpload = true;
          try {
            if (drivers[i].driverStatus !== undefined && drivers[i].driverStatus !== null) {
              (document.querySelector(`#DriverStatus_${i}_${drivers[i].id!}`) as HTMLSelectElement).value = drivers[i].driverStatus!;
            }
          } catch (e: any) {
            console.log(e);
          }
        }
        setDrivers([...order.drivers]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    let questionsValid = true;
    const items: Array<any> = [];
    drivers.forEach((it, index) => {
      const questions: Array<any> = [];
      it.driverQuestions!.filter(itq => itq.questionType === 'text').forEach(x => {
        const txt = (document.getElementById(`driver_${index}_${it.id}_question_${x.id!}`) as HTMLTextAreaElement).value;
        if (txt === undefined || txt === null || txt.trim().length === 0) {
          questionsValid = false;
        }
        questions.push({
          id: x.id,
          content: txt
        });
      });

      const item: any = {
        id: it.id,
        orderDriverId: it.orderDriverId,
        driverStatus: it.driverStatus,
        toUpload: it.toUpload,
        toDelete: it.toDelete,
        questions: questions
      };

      items.push(item);
    });

    data.drivers = items;
    data.orderNumber = orderNumber;

    if (drivers.length > 0) {
      if (questionsValid === true) {
        if (id !== undefined && id !== null) {
          try {
            await updateOrder(id, data);
            await handleFileUploadsForQuestions(id);
            await handleDeleteAnswerFileUploaded(id);
            toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
            setToasts([...toasts]);
            navigate("/orders");
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            const result: any = await createOrder(data);
            await handleFileUploadsForQuestions(result.order.id);
            await handleDeleteAnswerFileUploaded(result.order.id);
            toasts.push({
              title: t("Sukces"),
              content: t("Dane zapisane"),
              show: true,
              id: generate(32),
              type: "success",
            });
            setToasts([...toasts]);
            navigate("/orders");
          } catch (e) {
            console.log(e);
            toasts.push({
              title: t("Błąd"),
              content: t("Nie udało się zapisać"),
              show: true,
              id: generate(32),
              type: "danger",
            });
            setToasts([...toasts]);
          }
        }
      } else {
        toasts.push({
          title: t("Błąd"),
          content: t("Odpowiedzi na pytania w każdym sterowniku są wymagane."),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      toasts.push({
        title: t("Błąd"),
        content: t("Dodaj sterowniki do zlecenia. Nie można utworzyć pustego zlecenia."),
        show: true,
        id: generate(32),
        type: "danger",
      });
      setToasts([...toasts]);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const result: any = await listing(1, 20, '', null, null);
        setDriversOptions([...result.data]);
        setLoadingDrivers(false);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  const handleShowModalAddDriver = () => {
    setShowModalAddDriver(true);
  }

  const onModalAddDriverClose = (driver: Driver | null = null) => {
    if (driver !== null) {
      driver.toUpload = true;
      driver.orderDriverId = null;
      driver.filesForQuestionsBeforeUpload = [];
      driver.driverQuestions = [...driverQuestions];
      driver.answers = [];
      driver.showQuestions = true;
      if (driverStatuses.length > 0) {
        driver.driverStatus = driverStatuses[0].id;
      }
      drivers.push({...driver});
      setDrivers([...drivers]);
    }
    setShowModalAddDriver(false);
  }

  const cancelDriver = (index: number) => {
    if (drivers[index].orderDriverId === undefined || drivers[index].orderDriverId === null) {
      drivers.splice(index, 1);
      setDrivers([...drivers]);
    } else {
      drivers[index].toDelete = true;
      drivers[index].toUpload = false;
      setDrivers([...drivers]);
    }
  }

  const filterBy = () => true;

  const handleSearchOrderStatus = async (query: string) => {
    const rows = orderStatuses.filter((it) => it.title?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setOrderStatuses([...rows]);
  };

  const changedOrderStatus = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("currentStatus", (e[0] as OrderStatus).id!!);
    }
  };

  const handleSearchDriverInputChange = async (q: any) => {
    if (q.length === 0) {
      await handleSearchDriver('');
    }
  }

  const handleSearchDriver = async (query: string) => {
    setLoadingDrivers(true);
    try {
      const result: any = await listing(1, 20, query, null, null);
      setDriversOptions([...result.data]);
      setLoadingDrivers(false);
    } catch (e: any) {
      console.log(e);
      setLoadingDrivers(false);
    }
  };

  const changedSelectedDriver = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      e[0].toUpload = true;
      e[0].orderDriverId = null;
      e[0].filesForQuestionsBeforeUpload = [];
      e[0].driverQuestions = [...driverQuestions];
      e[0].answers = [];
      e[0].showQuestions = true;
      if (driverStatuses.length > 0) {
        e[0].driverStatus = driverStatuses[0].id;
      }
      setSelectedDriver(e[0]);
    }
  }

  const changedDriverStatus = (event: any, index: number) => {
    drivers[index].driverStatus = event.target.value;
    setDrivers([...drivers]);
  }

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setDrivers((prevItems: Driver[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex] as Driver],
        ],
      }),
    )
  }, [])

  // const renderItem = useCallback((d: Driver, index: number) => {
  //   return (
  // <SortableOrderDriverStatus index={index} d={d} moveItem={moveItem} driverStatuses={driverStatuses}
  //                            onCancelDriver={cancelDriver} onChangedDriverStatus={changedDriverStatus} />
  // )
  // }, [])

  const addDriverIntoOrder = () => {
    if (selectedDriver !== null) {
      selectedDriver.filesForQuestionsBeforeUpload = [];
      selectedDriver.driverQuestions = [...driverQuestions];
      selectedDriver.answers = [];
      selectedDriver.showQuestions = true;
      drivers.push({...selectedDriver});
      setDrivers([...drivers]);
    }
  }

  const changeShowDriverQuestions = (index: number) => {
    drivers[index]!.showQuestions = !drivers[index]!.showQuestions;
    setDrivers([...drivers]);
  }

  const markForDeleteFileAnswer = (driverIndex: number, driverQuestionId: string, driverAnswerFileId: string) => {
    if (drivers[driverIndex]!.answers !== undefined && drivers[driverIndex]!.answers !== null && drivers[driverIndex]!.answers!.length > 0) {
      const index = drivers[driverIndex]!.answers!.findIndex(it => it.driverQuestion === driverQuestionId!);
      if (index >= 0) {
        const files = drivers[driverIndex]!.answers!.find(it => it.driverQuestion === driverQuestionId)!.files!;
        const indexFile = files.findIndex(it => it.id! === driverAnswerFileId);
        if (index >= 0) {
          drivers[driverIndex]!.answers![index]!.files![indexFile]!.toDelete = true;
          setDrivers([...drivers]);
        }
      }
    }
  }

  const onDropFilesForQuestionsBeforeUpload = (driverIndex: number, acceptedFiles: any, driverQuestionId: string) => {
    let index: number = drivers[driverIndex]!.filesForQuestionsBeforeUpload!.findIndex(it => it.driverQuestionId === driverQuestionId);
    if (index < 0) {
      drivers[driverIndex]!.filesForQuestionsBeforeUpload!.push({
        driverQuestionId: driverQuestionId,
        files: acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploading: true,
            currentUploading: false,
          })
        )
      });
      setDrivers([...drivers]);
    } else {
      const files = drivers[driverIndex]!.filesForQuestionsBeforeUpload![index].files;
      acceptedFiles.forEach((file: any) => {
        files.push(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploading: true,
            currentUploading: false,
          })
        );
      });
      drivers[driverIndex]!.filesForQuestionsBeforeUpload![index].files = [...files];
      setDrivers([...drivers]);
    }
  }

  const getAnswerFiles = (driverIndex: number, driverQuestionId: string) => {
    let files: Array<DriverAnswerFile> = [];

    try {
      if (drivers[driverIndex]!.answers !== undefined && drivers[driverIndex]!.answers !== null && drivers[driverIndex]!.answers!.length > 0) {
        if (drivers[driverIndex]!.answers!.findIndex(it => it.driverQuestion === driverQuestionId!) >= 0) {
          files = drivers[driverIndex]!.answers!.find(it => it.driverQuestion === driverQuestionId)!.files!;
          if (files === undefined || files === null) {
            files = [];
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }

    return files;
  }

  const cancelFilesForQuestionsBeforeUpload = (driverIndex: number, index: number, questionId: string) => {
    try {
      const questionIndex = drivers[driverIndex]!.filesForQuestionsBeforeUpload!.findIndex(it => it.driverQuestionId === questionId);
      const files = drivers[driverIndex]!.filesForQuestionsBeforeUpload![questionIndex].files;
      files.splice(index, 1);
      drivers[driverIndex]!.filesForQuestionsBeforeUpload![questionIndex].files = files;
      setDrivers([...drivers]);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleFileUploadsForQuestions = async (orderId: string) => {
    for (let i = 0; i < drivers.length; i++) {
      const driver = {...drivers[i]!};
      if (driver.filesForQuestionsBeforeUpload !== undefined && driver.filesForQuestionsBeforeUpload !== null && driver.filesForQuestionsBeforeUpload.length > 0) {
        const files = driver.filesForQuestionsBeforeUpload;
        for (let j = 0; j < files.length; j++) {
          for (let k = 0; k < (driver.filesForQuestionsBeforeUpload as any)[j].files.length; k++) {
            await uploadSingleFileForQuestion(orderId, driver.id!, driver.filesForQuestionsBeforeUpload[j].driverQuestionId, i, j, k);
          }
        }
      }
    }
  }

  const uploadSingleFileForQuestion = async (orderId: string, driverId: string, driverQuestionId: string, i: number, j: number, k: number) => {
    const headers: any = getHeaderOptions();
    const formData = new FormData();
    formData.set("file", (drivers[i]!.filesForQuestionsBeforeUpload as any)[j]!.files[k], (drivers[i]!.filesForQuestionsBeforeUpload as any)[j]!.files[k].name);
    formData.set("position", (i + 1).toString());
    if (drivers[i]!.orderDriverId !== undefined && drivers[i]!.orderDriverId !== null) {
      formData.set("orderDriverId", drivers[i]!.orderDriverId!);
    }
    await axios.post(`${API_URL}/driver/${driverId}/question/${driverQuestionId}/order/${orderId}/upload`, formData, headers);
  };

  const handleDeleteAnswerFileUploaded = async (orderId: string) => {
    for (let i = 0; i < drivers.length; i++) {
      const driver = drivers[i]!;
      if (driver.answers !== undefined && driver.answers !== null && driver.answers.length > 0) {
        for (let j = 0; j < driver.answers.length; j++) {
          if (driver.answers[j].files !== undefined && driver.answers[j].files !== null && driver.answers[j]!.files!.length > 0) {
            for (let k = 0; k < driver.answers[j]!.files!.length; k++) {
              if (driver.answers[j]!.files![k]!.toDelete) {
                await forceDeleteDriverAnswerFile(driver!.id!, driver.answers[j].driverQuestion, driver.answers[j]!.files![k].id!, orderId);
              }
            }
          }
        }
      }
    }
  }

  const showValidationForQuestion = (inputId: string) => {
    console.log('showValidationForQuestion', inputId);
    let result = true;
    try {
      const el = (document.querySelector(`#${inputId}`) as HTMLTextAreaElement);
      if (el !== undefined && el !== null) {
        if (el.value === undefined || el.value === null || el.value.trim().length === 0) {
          if (!el.classList.contains('has-error')) {
            el.classList.add('has-error');
          }
        } else {
          result = false;
          if (el.classList.contains('has-error')) {
            el.classList.remove('has-error');
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
    return result;
  }

  return (
    <>
      <Modal show={showModalAddDriver} size={'xl'} dialogClassName={'modal-huge'}
             onHide={() => setShowModalAddDriver(false)}
             onBackdropClick={() => setShowModalAddDriver(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Dodaj sterownik")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DriverForm asModal={true} onModalClose={onModalAddDriverClose} />
        </Modal.Body>
      </Modal>
      <div className="row mb-5">
        <div className="col-12">
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="header-title">
                    <span>{t('Zlecenie numer:')}</span>
                    <span> {orderNumber}</span>
                  </h4>
                  <div>
                    <button type={'button'} className="btn btn-success mb-2" onClick={handleShowModalAddDriver}>
                      <i className="mdi mdi-plus-circle me-2"></i>
                      {t("Dodaj nowy sterownik")}
                    </button>
                  </div>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="form-group position-relative">
                      <div className="input-group">
                        <AsyncTypeahead
                          filterBy={filterBy}
                          id="async-driver"
                          isLoading={loadingDrivers}
                          labelKey="driverNumber"
                          minLength={0}
                          onSearch={handleSearchDriver}
                          onInputChange={handleSearchDriverInputChange}
                          onChange={(e) => changedSelectedDriver(e)}
                          options={driversOptions}
                          useCache={false}
                          placeholder={t("Dodaj sterownik do zlecenia")}
                          inputProps={{className: 'form-control-lg'}}
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option.driverType}</span>
                              {option.driverNumber !== undefined && option.driverNumber !== null && option.driverNumber.length > 0 ? (
                                <span> / {option.driverNumber} </span>
                              ) : <></>}
                              {option.driverCode !== undefined && option.driverCode !== null && option.driverCode.length > 0 ? (
                                <span> / {option.driverCode} </span>
                              ) : <></>}
                            </>
                          )}
                        />
                        <button className="input-group-text btn btn-success" type="button"
                                onClick={addDriverIntoOrder}>
                          <span className="mdi mdi-plus search-icon form-control-lg fs-3 text-white"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {order !== undefined && order !== null ? (
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Status zlecenia")}</label>
                        <AsyncTypeahead
                          defaultInputValue={order !== undefined && order !== null ? order.CurrentStatus?.title : ""}
                          filterBy={filterBy}
                          id="async-car-brand"
                          isLoading={false}
                          labelKey="title"
                          minLength={0}
                          onSearch={handleSearchOrderStatus}
                          onChange={(e) => changedOrderStatus(e)}
                          options={orderStatusesOptions}
                          placeholder={t("Status zlecenia")}
                          inputProps={{className: 'form-control-lg'}}
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option.title}</span>
                            </>
                          )}
                        />
                        {errors && errors.currentStatus !== undefined ? (
                          <span className={"error-message"}>{t(errors.currentStatus?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ) : <></>}
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="deliveryMethod" className="form-label">
                        {t("Metoda wysyłki")}
                      </label>
                      <select
                        className={"form-control form-control-lg" + (errors && errors.deliveryMethod !== undefined ? " has-error" : "")}
                        id="deliveryMethod"
                        {...register("deliveryMethod")}
                        defaultValue={""}
                      >
                        <option value={'self'}>{t('Dostawa własna')}</option>
                        <option value={'dpd'}>{t('Kurier DPD')}</option>
                      </select>
                      {errors && errors.deliveryMethod !== undefined ? (
                        <span className={"error-message"}>{t(errors.deliveryMethod?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="dropzone-previews mt-3">
                      {drivers.map((d, index) => (
                        <div
                          className={`card mt-1 mb-0 shadow-none border${d.toDelete ? ' disabled' : ''}${index % 2 === 0 ? ' card--lighter' : ' card--darker'}`}
                          key={`DriverBeforeUpload${index}`}>
                          <div className="p-2">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <div className="avatar-sm">
                                <span className="avatar-title rounded">
                                  <i className="mdi mdi-flash-outline font-16"></i>
                                </span>
                                </div>
                              </div>
                              <div className="col ps-0">
                                <div className={'d-flex flex-row align-items-center'}>
                                  <div className={'pe-5'}>
                                    <a className="file_name text-muted fw-bold" data-dz-name="">
                                      {d.driverType}
                                      {d.driverNumber !== undefined && d.driverNumber !== null && d.driverNumber.length > 0 ? ` / ${d.driverNumber}` : ''}
                                      {d.driverCode !== undefined && d.driverCode !== null && d.driverCode.length > 0 ? ` / ${d.driverCode}` : ''}
                                    </a>
                                    <p className="mb-0 file_name" data-dz-size="">
                                      <strong>{d.CarBrand?.title} {d.CarModel?.title}</strong>
                                    </p>
                                  </div>
                                  <div>
                                    {d.year !== undefined && d.year !== null && d.year > 0 ? (
                                      <span>{t('Rok')}: {d.year}</span>
                                    ) : <></>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto d-flex align-items-center">
                                <button type={'button'} onClick={() => changeShowDriverQuestions(index)}
                                        className={'btn btn-outline-primary btn-lg me-3 text-no-wrap'}>
                                  {!d.showQuestions ? (
                                    <i className={'mdi mdi-eye'}></i>
                                  ) : (
                                    <i className={'mdi mdi-eye-off'}></i>
                                  )}
                                  <span className={'ms-2'}>
                                    {!d.showQuestions ? t('Pokaż pytania') : t('Zamknij pytania')}
                                  </span>
                                </button>
                                <select id={`DriverStatus_${index}_${d.id!}`}
                                        onChange={(e: any) => changedDriverStatus(e, index)}
                                        className={'form-control form-control-lg select-with-arrow me-5'}>
                                  {driverStatuses.map((driverStatus, statusIndex) => (
                                    <option
                                      key={`DriverStatusSelect_${index}_${statusIndex}`}
                                      value={driverStatus.id!}>{driverStatus.title}</option>
                                  ))}
                                </select>
                                <a
                                  onClick={() => cancelDriver(index)}
                                  className="btn btn-link btn-lg text-muted"
                                  data-dz-remove=""
                                >
                                  <i className="mdi mdi-close"></i>
                                </a>
                              </div>
                            </div>
                            <div className={d.showQuestions ? 'show' : 'hidden'}>
                              <hr />
                              <div
                                className={'text-primary fw-bold mb-3'}>{t('Odpowiedzi na pytania są wymagane.')}</div>
                              <div className="row">
                                {d.driverQuestions!.map((q, qIndex) => (
                                  <div className="col-12 col-md-6 mb-4" key={`DriverQuestion-${qIndex}`}>
                                    <label htmlFor={`question${qIndex + 1}`} className="form-label">{q.title}</label>
                                    {q.questionType === 'text' ? (
                                      <textarea
                                        className={`form-control${showValidationForQuestion(`driver_${index}_${d.id}_question_${q.id!}`) ? ' has-error' : ''}`}
                                        rows={6}
                                        id={`driver_${index}_${d.id}_question_${q.id!}`}
                                        onBlur={() => showValidationForQuestion(`driver_${index}_${d.id}_question_${q.id!}`)}
                                        onChange={() => showValidationForQuestion(`driver_${index}_${d.id}_question_${q.id!}`)}
                                        defaultValue={d.answers!.length > 0 && d.answers!?.findIndex(it => it.driverQuestion === q.id!) >= 0 ? d.answers!?.find(it => it.driverQuestion === q.id!)!.content : ''}></textarea>
                                    ) : (
                                      q.questionType === 'upload' ? (
                                        <>
                                          <Dropzone
                                            onDrop={(files) => onDropFilesForQuestionsBeforeUpload(index, files, q.id!)}
                                            disabled={false}>
                                            {({getRootProps, getInputProps}) => (
                                              <div {...getRootProps({className: "dropzone"})} className="dropzone">
                                                <div className="dz-message">
                                                  <i className="icon mdi mdi-cloud-upload-outline"></i>
                                                  <h4 className="text">{t("Upuść plik lub kliknij żeby wgrać")}</h4>
                                                </div>
                                                <input className="d-none form-file-input-selector" type="file"
                                                       id={`formFile${index}_${qIndex}`} />
                                              </div>
                                            )}
                                          </Dropzone>
                                          <div className="dropzone-previews mt-3">
                                            {getAnswerFiles(index, q.id!).map((file, fileIndex) => (
                                              <div
                                                className={`card mt-1 mb-0 shadow-none border${file.toDelete ? ' disabled' : ''}`}
                                                key={`QuestionAnswerFile${index}_${qIndex}_${fileIndex}`}>
                                                <div className="p-2">
                                                  <div className="row align-items-center">
                                                    <div className="col-auto">
                                                      <div className="avatar-sm">
                                                        <span className="avatar-title rounded">
                                                          <i className="mdi mdi-file font-16"></i>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col ps-0">
                                                      <a className="file_name text-muted fw-bold" data-dz-name="">
                                                        {file.originalFileName}
                                                      </a>
                                                      <p className="mb-0 file_name" data-dz-size="">
                                                        <strong>{(file.fileSize / 1024 / 1024).toFixed(2)}</strong>MB
                                                      </p>
                                                    </div>
                                                    <div className="col-auto">
                                                      {file.path !== undefined && file.path !== null && file.path.length > 0 ? (
                                                        <a className={'btn btn-outline-primary ms-3'}
                                                           onClick={(e: any) => {
                                                             e.preventDefault();
                                                             e.stopPropagation();
                                                             const link: any = document.createElement("a");
                                                             link.target = '_blank';
                                                             link.download = file.originalFileName;
                                                             link.href = file.path;
                                                             document.body.appendChild(link);
                                                             link.click();
                                                             document.body.removeChild(link);
                                                           }}>
                                                          <i className="mdi mdi-download font-16 me-2"></i>
                                                          <span>{t('Pobierz')}</span>
                                                        </a>
                                                      ) : <></>}
                                                      {!file.toDelete ? (
                                                        <a
                                                          onClick={() => markForDeleteFileAnswer(index, q.id!, file.id!)}
                                                          className="btn btn-link btn-lg text-muted"
                                                          data-dz-remove=""
                                                        >
                                                          <i className="mdi mdi-close"></i>
                                                        </a>
                                                      ) : <></>}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                            {d.filesForQuestionsBeforeUpload!.find(it => it.driverQuestionId === q.id!)?.files?.map((file: any, ind: number) => (
                                              <div className="card mt-1 mb-0 shadow-none border"
                                                   key={`FileBeforeUpload${ind}`}>
                                                <div className="p-2">
                                                  <div className="row align-items-center">
                                                    <div className="col-auto">
                                                      <div className="avatar-sm">
                                                        <span className="avatar-title rounded">
                                                          <i className="mdi mdi-file font-16"></i>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col ps-0">
                                                      <a className="file_name text-muted fw-bold" data-dz-name="">
                                                        {file.path}
                                                      </a>
                                                      <p className="mb-0 file_name" data-dz-size="">
                                                        <strong>{(file.size / 1024 / 1024).toFixed(2)}</strong>MB
                                                      </p>
                                                    </div>
                                                    <div className="col-auto">
                                                      {file.path !== undefined && file.path !== null && file.path.length > 0 ? (
                                                        <a className={'btn btn-outline-primary ms-3'}
                                                           target={'_blank'} href={file.path}>
                                                          <i className="mdi mdi-download font-16 me-2"></i>
                                                          <span>{t('Pobierz')}</span>
                                                        </a>
                                                      ) : <></>}
                                                      <a
                                                        onClick={() => cancelFilesForQuestionsBeforeUpload(index, ind, q.id!)}
                                                        className="btn btn-link btn-lg text-muted"
                                                        data-dz-remove=""
                                                      >
                                                        <i className="mdi mdi-close"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="title" className="form-label">
                        {t("Dodatkowe informacje do zlecenia")}
                      </label>
                      <textarea
                        className={"form-control form-control-lg" + (errors && errors.info !== undefined ? " has-error" : "")}
                        id="info"
                        rows={6}
                        {...register("info", {required: false})}
                        defaultValue={""}
                      ></textarea>
                      {errors && errors.info !== undefined ? (
                        <span className={"error-message"}>{t(errors.info?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer p-4 text-muted">
                <div className="row">
                  <div className="col-12 text-end">
                    <Link className="btn btn-outline-primary ms-3" to={"/orders"}>
                      {t("Anuluj")}
                    </Link>
                    {id !== undefined && id !== null ? (
                      <button className="btn btn-outline-primary ms-3">{t("Zapisz zlecenie")}</button>
                    ) : (
                      <button className="btn btn-outline-primary ms-3">{t("Dodaj zlecenie")}</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
