import React, {useContext, useState} from "react";
import {LayoutContext} from "../../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import DriverStatusForm from '../../../components/forms/driver-status-form';
import useDriverStatus from '../../../hooks/use-driver-status';
import {DriverStatus} from '../../../types/driver-status';

export default function DriverStatusEdit() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const params = useParams();
  const {getById} = useDriverStatus();
  const [driverStatus, setDriverStatus] = useState<DriverStatus | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setDriverStatus(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {
        label: t("Statusy sterowników"),
        path: "/settings/driver-status",
      },
      {path: null, label: t("Edytuj")},
    ];
    setLayout({...layout});
  }, []);

  return driverStatus !== undefined && driverStatus !== null ? (
    <DriverStatusForm id={params.id || null} driverStatus={driverStatus} />
  ) : (
    <></>
  );
}
