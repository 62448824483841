import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions} from "../lib/request-helper";
import {ShowLoaderContext} from "./show-loader-context";
import {Order} from "../types/order";
import {FormInputOrder} from "../types/forms";
import {DpdOrder} from '../types/dpd';

export default function useOrders() {
  const {setShowLoader} = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null,
    filters: any = null
  ): Promise<any> => {
    setShowLoader(true);
    let isExport: boolean = false;
    if (filters !== undefined && filters !== null && filters.export !== undefined && filters.export === 'on') {
      isExport = true;
    }
    try {
      let urlPath: string = `${API_URL}/order${isExport ? '/export' : ''}?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`;
      if (filters !== undefined && filters !== null) {
        const keys = Object.keys(filters);
        for (let k of keys) {
          urlPath += `&${k}=${filters[k]}`;
        }
      }
      if (isExport) {
        window.open(urlPath, "_blank")!!.focus();
      } else {
        const result = await axios.get(urlPath, getHeaderOptions());
        setShowLoader(false);
        return result.data;
      }
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingArchived = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null,
    filters: any = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      let urlPath: string = `${API_URL}/order/archived?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`;
      if (filters !== undefined && filters !== null) {
        const keys = Object.keys(filters);
        for (let k of keys) {
          urlPath += `&${k}=${filters[k]}`;
        }
      }
      const result = await axios.get(urlPath, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleOrders = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingOrdersForSendBack = async (partnerId: string, excludeId: string = ''): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order/listing/send/back/${partnerId}?excludeId=${excludeId}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<Order> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createOrder = async (inputData: FormInputOrder): Promise<Order> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createOrderSendDriversBack = async (inputData: FormInputOrder): Promise<Order> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order/send/drivers/back`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createOrderNumber = async (): Promise<Order> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order/generate/order-number`, {}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateOrder = async (id: string, inputData: FormInputOrder | { active: boolean }): Promise<Order> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/order/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteOrder = async (id: number | string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/order/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const undoDeleteOrder = async (id: number | string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order/delete/undo/${id}`, {}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createDpdOrder = async (orderId: number | string, data: any): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order/delivery/dpd/${orderId}`, data, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getOrdersDpdList = async (): Promise<Array<DpdOrder>> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order/dpd/future/list`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingArchived,
    listingSimpleOrders,
    listingOrdersForSendBack,
    getById,
    createOrder,
    createOrderSendDriversBack,
    createOrderNumber,
    updateOrder,
    forceDeleteOrder,
    undoDeleteOrder,
    createDpdOrder,
    getOrdersDpdList,
  };
}
