import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../../hooks/layout-context";

interface MenuItemProps {
  drawerWidth: number;
  currentPath: string;
  pathName: string;
  children?: any;
  click?: any;
}

interface MenuLinkItemProps extends MenuItemProps {
  icon: string;
  label: string;
  to?: string;
}

interface LinkContainerProps {
  to?: string;
  click?: any;
  children: any;
}

const LinkContainer = ({ to, click, children }: LinkContainerProps) => {
  return to !== undefined && to !== null ? (
    <Link className="aside-nav-link" to={to!!}>
      {children}
    </Link>
  ) : (
    <a className="aside-nav-link" onClick={click}>
      {children}
    </a>
  );
};

export default function MenuListLink({
  drawerWidth,
  pathName,
  currentPath,
  to,
  icon,
  label,
  children,
  click,
}: MenuLinkItemProps) {
  const { layout, setLayout } = useContext(LayoutContext);

  const expandMenu = () => {
    if (layout.expanded === undefined || layout.expanded === null) {
      layout.expanded = [];
    }
    if (layout.expanded.indexOf(pathName) === -1) {
      layout.expanded.push(pathName);
    } else {
      layout.expanded.splice(layout.expanded.indexOf(pathName), 1);
    }
    setLayout({ ...layout });
  };

  return (
    <li className={"aside-nav-item" + (currentPath === pathName ? " active" : "")}>
      <div className={"aside-nav-holder"}>
        <LinkContainer to={to} click={click}>
          <i className={["mdi", icon].join(" ")}></i>
          {drawerWidth === 260 ? <span>{label}</span> : <></>}
        </LinkContainer>
        {children ? <span className="menu-arrow" onClick={expandMenu}></span> : <></>}
      </div>
      {children}
    </li>
  );
}
