import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {AppConfig} from "../../types/app-config";
import useAppConfig from "../../hooks/use-app-config";
import {FormInputAppConfig} from "../../types/forms";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import useOrderStatus from '../../hooks/use-order-status';
import {OrderStatus} from '../../types/order-status';
import {CarModel} from '../../types/car';
import {Partner} from '../../types/partner';
import usePartners from '../../hooks/use-partners';

interface AppConfigFormProps {
  appConfig?: AppConfig | null;
  id?: string | null;
}

export default function AppConfigForm({id = null, appConfig = null}: AppConfigFormProps) {
  const {t} = useTranslation();
  const {updateAppConfig} = useAppConfig();
  const {listingSimpleOrderStatus} = useOrderStatus();
  const {listingSimplePartners} = usePartners();
  const navigate = useNavigate();
  const [orderStatuses, setOrderStatuses] = useState<Array<OrderStatus>>([]);
  const [orderStatusesForCreatedOptions, setOrderStatusesForCreatedOptions] = useState<Array<OrderStatus>>([]);
  const [orderStatusesForFinishedOptions, setOrderStatusesForFinishedOptions] = useState<Array<OrderStatus>>([]);
  const [orderStatusesForSendDriversBackOptions, setOrderStatusesForSendDriversBackOptions] = useState<Array<OrderStatus>>([]);
  const [partners, setPartners] = useState<Array<Partner>>([]);
  const [partnersDestination, setPartnersDestination] = useState<Array<Partner>>([]);
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputAppConfig>();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    if (appConfig !== undefined && appConfig !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputAppConfig);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputAppConfig, appConfig[k as keyof AppConfig]);
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleOrderStatus();
        setOrderStatuses([...rows]);
        setOrderStatusesForCreatedOptions([...rows]);
        setOrderStatusesForFinishedOptions([...rows]);
        setOrderStatusesForSendDriversBackOptions([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async() => {
      try {
        const rows = await listingSimplePartners();
        setPartners(rows);
        setPartnersDestination(rows);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  const filterBy = () => true;

  const handleSearchOrderStatusForCreated = async (query: string) => {
    const rows = orderStatuses.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setOrderStatusesForCreatedOptions([...rows]);
  };

  const handleSearchOrderStatusForFinished = async (query: string) => {
    const rows = orderStatuses.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setOrderStatusesForFinishedOptions([...rows]);
  };

  const handleSearchOrderStatusForSendDriversBack = async (query: string) => {
    const rows = orderStatuses.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setOrderStatusesForSendDriversBackOptions([...rows]);
  };

  const handleSearchPartnerDestination = async (query: string) => {
    const rows = partners.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setPartnersDestination([...rows]);
  };

  const changedPartnerDestination = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("partnerDestination", (e[0] as Partner).id!!);
    }
  };

  const changedOrderStatusForCreated = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("orderStatusForCreated", (e[0] as OrderStatus).id!!);
    }
  };

  const changedOrderStatusForFinished = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("orderStatusForFinished", (e[0] as OrderStatus).id!!);
    }
  };

  const changedOrderStatusForSendDriversBack = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("orderStatusForSendDriversBack", (e[0] as OrderStatus).id!!);
    }
  };

  const handleLogin = async (data: any) => {
    try {
      await updateAppConfig(data);
      toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
      setToasts([...toasts]);
      navigate("/settings");
    } catch (e) {
      console.log(e);
      toasts.push({
        title: t("Błąd"),
        content: t("Nie udało się zapisać"),
        show: true,
        id: generate(32),
        type: "danger",
      });
      setToasts([...toasts]);
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t("Edytuj konfigurację")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Domena")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.domain !== undefined ? " has-error" : "")}
                      id="domain"
                      {...register("domain", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.domain !== undefined ? (
                      <span className={"error-message"}>{t(errors.domain?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Oddział/Serwis odbiera zlecenie")}</label>
                    <AsyncTypeahead
                      defaultInputValue={appConfig !== undefined && appConfig !== null ? appConfig.PartnerDestination?.title : ""}
                      filterBy={filterBy}
                      id="async-partner-destination"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchPartnerDestination}
                      onChange={(e) => changedPartnerDestination(e)}
                      options={partnersDestination}
                      placeholder={t("Wybierz oddział/serwis")}
                      inputProps={{className: 'form-control-lg'}}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title} ({option.city}, {option.street})</span>
                        </>
                      )}
                    />
                    {errors && errors.partnerDestination !== undefined ? (
                      <span className={"error-message"}>{t(errors.partnerDestination?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Status nowego zlecenia")}</label>
                    <AsyncTypeahead
                      defaultInputValue={appConfig !== undefined && appConfig !== null ? appConfig.OrderStatusForCreated?.title : ""}
                      filterBy={filterBy}
                      id="async-car-brand"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchOrderStatusForCreated}
                      onChange={(e) => changedOrderStatusForCreated(e)}
                      options={orderStatusesForCreatedOptions}
                      placeholder={t("Wybierz status")}
                      inputProps={{className: 'form-control-lg'}}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.orderStatusForCreated !== undefined ? (
                      <span className={"error-message"}>{t(errors.orderStatusForCreated?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Status nowego zlecenia - wyślij gotowe sterowniki")}</label>
                    <AsyncTypeahead
                      defaultInputValue={appConfig !== undefined && appConfig !== null ? appConfig.OrderStatusForSendDriversBack?.title : ""}
                      filterBy={filterBy}
                      id="async-car-brand"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchOrderStatusForSendDriversBack}
                      onChange={(e) => changedOrderStatusForSendDriversBack(e)}
                      options={orderStatusesForSendDriversBackOptions}
                      placeholder={t("Wybierz status")}
                      inputProps={{className: 'form-control-lg'}}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.orderStatusForSendDriversBack !== undefined ? (
                      <span className={"error-message"}>{t(errors.orderStatusForSendDriversBack?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Status zakończonego zlecenia")}</label>
                    <AsyncTypeahead
                      defaultInputValue={appConfig !== undefined && appConfig !== null ? appConfig.OrderStatusForFinished?.title : ""}
                      filterBy={filterBy}
                      id="async-car-brand"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchOrderStatusForFinished}
                      onChange={(e) => changedOrderStatusForFinished(e)}
                      options={orderStatusesForFinishedOptions}
                      placeholder={t("Wybierz status")}
                      inputProps={{className: 'form-control-lg'}}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.orderStatusForFinished !== undefined ? (
                      <span className={"error-message"}>{t(errors.orderStatusForFinished?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-4">
                  <div className="form-check form-switch mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      {...register("showDriverFileUpload")}
                      id={`showDriverFileUpload`}
                    />
                    <label className="form-check-label" htmlFor={`showDriverFileUpload`}>
                      {t('Pokaż upload plików w sterownikach')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings"}>
                    {t("Anuluj")}
                  </Link>
                  <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
