import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { LayoutContext } from "../hooks/layout-context";
import styled from "@emotion/styled";
import { UserContext } from "../hooks/user-context";
import useCar from "../hooks/use-car";
import { DashboardStats } from "../types/car";
import moment from "moment";

const ImageAvatarStyled = styled("img")`
  height: 100px;
`;

export default function Home() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user, isLoading } = useContext(UserContext);

  React.useEffect(() => {
    layout.sideMenu = "home";
    layout.showSearch = false;
    layout.breadcrumbs = [];
    setLayout({ ...layout });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <div className="row mb-5">
        <div className="col-xxl-3 col-sm-6 mb-4">
          <div className="card shadow h-100">
            <div className="card-body">
              <span className="float-start m-2 me-4">
                <ImageAvatarStyled src="/svg/avatar-person.svg" className="rounded-circle img-thumbnail" />
              </span>
              <div className="">
                <h4 className="mt-1 mb-1">{user ? user.firstName : ""}</h4>
                <p className="font-13"> {user ? user.positionName : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
