import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import useLogout from "../../hooks/use-logout";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import MenuListLink from "./side-menu/menu-list-link";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/use-auth";
import { UserContext } from "../../hooks/user-context";
import { version } from "../../../package.json";

export default function SideMenu({ drawerWidth }: any) {
  const { logoutUser } = useLogout();
  const { layout, setLayout } = useContext(LayoutContext);
  const { setUser } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await logoutUser();
      await logout();
      setUser(null);
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <aside className={`aside-left${drawerWidth === 60 ? " smaller" : ""}`} style={{ width: `${drawerWidth}px` }}>
      <Link to={"/"} className="logo">
        <img className={"logo-svg logo-desktop"} src={"/svg/logo.svg"} />
        <img className={"logo-svg logo-mob"} src={"/svg/logo-small.svg"} />
      </Link>
      <div className="aside-container">
        <nav className="aside-nav">
          {!isLoading && user !== undefined && user !== null ? (
            <ul className="aside-nav">
              <li className="aside-nav-title">{t("Nawigacja")}</li>
              <MenuListLink
                icon={"mdi-home-outline"}
                label={t("Home")}
                to={"/"}
                drawerWidth={drawerWidth}
                currentPath={layout.sideMenu}
                pathName={"home"}
              />
              {user.permissions.indexOf("orders") !== -1 ? (
                <MenuListLink
                  icon={"mdi-order-bool-ascending-variant"}
                  label={t("Zlecenia")}
                  to={"/orders"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"orders"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "orders" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("orders") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("orders") !== -1 ? (
                        <li>
                          <Link to={"/orders"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszystkie zlecenia")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("orders.archived") !== -1 ? (
                        <li>
                          <Link to={"/orders/archived"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Archiwum zleceń")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("orders.add") !== -1 ? (
                        <li>
                          <Link to={"/orders/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj zlecenie")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("drivers") !== -1 ? (
                <MenuListLink
                  icon={"mdi-flash-outline"}
                  label={t("Sterowniki")}
                  to={"/drivers"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"drivers"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "drivers" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("drivers") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("drivers") !== -1 ? (
                        <li>
                          <Link to={"/drivers"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszystkie sterowniki")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("drivers.add") !== -1 ? (
                        <li>
                          <Link to={"/drivers/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj sterownik")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("partners") !== -1 ? (
                <MenuListLink
                  icon={"mdi-domain"}
                  label={t("Oddziały")}
                  to={"/partners"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"partners"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "partners" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("partners") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("partners") !== -1 ? (
                        <li>
                          <Link to={"/partners"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszystkie oddziały")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("partners.add") !== -1 ? (
                        <li>
                          <Link to={"/partners/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj oddział")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("employee") !== -1 ? (
                <MenuListLink
                  icon={"mdi-account-multiple"}
                  label={t("Użytkownicy")}
                  to={"/employee"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"employee"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "employee" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("employee") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("employee") !== -1 ? (
                        <li>
                          <Link to={"/employee"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszyscy użytkownicy")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("employee.add") !== -1 ? (
                        <li>
                          <Link to={"/employee/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj użytkownika")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("settings") !== -1 ? (
                <MenuListLink
                  icon={"mdi-cog-outline"}
                  label={t("Ustawienia")}
                  to={"/settings"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"settings"}
                />
              ) : (
                <></>
              )}
              <MenuListLink
                icon={""}
                label={t("Edytuj swoje konto")}
                to={"/my-account"}
                drawerWidth={drawerWidth}
                currentPath={layout.sideMenu}
                pathName={"my-account"}
              />
              <MenuListLink
                icon={"mdi-logout"}
                label={t("Wyloguj")}
                drawerWidth={drawerWidth}
                currentPath={layout.sideMenu}
                pathName={"logout"}
                click={handleLogout}
              />
            </ul>
          ) : (
            <></>
          )}
        </nav>
      </div>
    </aside>
  );
}
