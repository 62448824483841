import React, {useContext, useState} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useDrivers from "../../hooks/use-drivers";
import {Driver, DriverAnswer} from "../../types/driver";
import DriverForm from '../../components/forms/driver-form';

export default function DriversEdit() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const params = useParams();
  const {getById} = useDrivers();
  const [driver, setDriver] = useState<Driver | null>();
  const [answers, setAnswers] = useState<Array<DriverAnswer>>([]);

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setAnswers([...result.answers]);
          setDriver(result.driver);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "drivers";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Sterowniki"), path: "/drivers"},
      {path: null, label: t("Edytuj")},
    ];
    setLayout({...layout});
  }, []);

  return driver !== undefined && driver !== null ? (
    <DriverForm id={params.id || null} driver={driver} answersInput={answers} />
  ) : (
    <></>
  );
}
