import React, {useContext, useState} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import DriversSendBackForm from '../../components/forms/drivers-send-back-form';
import {AppConfig} from '../../types/app-config';
import {UserContext} from '../../hooks/user-context';
import useAppConfig from '../../hooks/use-app-config';
import MissingPermission from '../missing-permission';

export default function OrdersDriversSendBack() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const {user, isLoading} = useContext(UserContext);
  const {getAppConfig} = useAppConfig();
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);

  React.useEffect(() => {
    layout.sideMenu = "orders";
    layout.breadcrumbs = [
      {label: t("Zlecenia"), path: "/orders"},
      {path: null, label: t("Wyślij gotowe sterowniki")},
    ];
    setLayout({...layout});
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const cfg = await getAppConfig();
        setAppConfig(cfg);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      {!isLoading &&
      appConfig !== undefined &&
      appConfig !== null &&
      user !== undefined &&
      user !== null &&
      appConfig.partnerDestination === user.partner &&
      user.permissions.indexOf("orders.add") !== -1 ? (
        <DriversSendBackForm />
      ) : (
        <>
          {!isLoading &&
          appConfig !== undefined &&
          appConfig !== null &&
          user !== undefined &&
          user !== null &&
          (appConfig.partnerDestination !== user.partner || user.permissions.indexOf("orders.add") === -1) ? (
            <MissingPermission />
          ) : <></>}
        </>
      )}
    </>
  )
}
