import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../hooks/layout-context";
import {Link} from "react-router-dom";
import {Driver} from "../../types/driver";
import useDrivers from "../../hooks/use-drivers";
import DataTable from "react-data-table-component";
import {Dropdown} from "react-bootstrap";
import Confirmation from "../../components/layout/confirmation";
import {generate} from "../../lib/random-id-lib";
import {ToastsContext} from "../../hooks/toasts-context";
import {UserContext} from "../../hooks/user-context";
import Moment from 'react-moment';

export default function DriversList() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Driver>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const {listing, updateDriver, forceDeleteDriver} = useDrivers();
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [selectedDriverDelete, setSelectedDriverDelete] = useState<Driver | null>(null);
  const {toasts, setToasts} = useContext(ToastsContext);
  const {user, isLoading} = useContext(UserContext);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  React.useEffect(() => {
    layout.sideMenu = "drivers";
    layout.showSearch = true;
    layout.breadcrumbs = [{path: null, label: t("Sterowniki")}];
    setLayout({...layout});
  }, []);

  React.useEffect(() => {
    if (configLoaded) {
      if (layout.topSearch !== search) {
        setSearch(layout.topSearch);
      }
    }
  }, [layout.topSearch]);

  React.useEffect(() => {
    const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
    if (searchControl !== undefined && searchControl !== null) {
      if (search !== undefined && search !== null && search.length > 0 && search !== 'undefined' && search !== 'null') {
        setTimeout(() => {
          searchControl.value = search;
          searchControl.setAttribute('value', search);
        });
      }
    }
  }, [search, layout.showSearch]);

  const columns: Array<any> = [
    {
      name: t("Nazwa sterownika"),
      sortable: true,
      key: "driverType",
      selector: (row: any) => <span>{row.driverType}</span>,
      right: false,
    },
    {
      name: t("Numer sterownika"),
      sortable: true,
      key: "driverNumber",
      selector: (row: any) => <span>{row.driverNumber}</span>,
      right: false,
    },
    {
      name: t("Marka"),
      sortable: false,
      key: "carBrand",
      selector: (row: any) => <span>{row.CarBrand.title}</span>,
      right: false,
    },
    {
      name: t("Model"),
      sortable: false,
      key: "carModel",
      selector: (row: any) => <span>{row.CarModel.title}</span>,
      right: false,
    },
    {
      name: t("Rok"),
      sortable: true,
      key: "year",
      selector: (row: any) => <span>{row.year}</span>,
      right: false,
    },
    {
      name: t("Kod wewnętrzny"),
      sortable: true,
      key: "driverCode",
      selector: (row: any) => <span>{row.driverCode}</span>,
      right: false,
    },
    {
      name: t("Dodany przez"),
      sortable: true,
      key: "partner",
      selector: (row: any) => <span className={'text-wrap'}>{row.Partner?.title}, {row.Partner?.city}, {row.Partner?.street}</span>,
      right: false,
    },
    {
      name: t("Data dodania"),
      sortable: true,
      key: "createdAt",
      selector: (row: any) => <Moment format={'YYYY-MM-DD HH:mm'}>{row.createdAt}</Moment>,
      right: false,
    },
    {
      name: "",
      selector: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={"div"}>
              <Link to={`/drivers/edit/${row.id}`}>{t("Edytuj")}</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDriverDelete(row)}>{t("Usuń")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      right: true,
      width: '50px',
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null,
    sSearch: string | null = null
  ) => {
    try {
      localStorage.setItem('table_drivers', JSON.stringify({
        page, currentPerPage, sField, sDirection, search
      }));
      const result = await listing(page, currentPerPage, sSearch === undefined || sSearch === null ? search : sSearch, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const deleteDriver = async (id: any) => {
    if (selectedDriverDelete !== undefined && selectedDriverDelete !== null) {
      try {
        await forceDeleteDriver(selectedDriverDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Sterownik usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage);
    }

    setSelectedDriverDelete(null);
  };

  const handleSort = async (column: any, direction: any) => {
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem('table_drivers');
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            setSearch(tableConfig.search);
            const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
            if (searchControl !== undefined && searchControl !== null) {
              if (tableConfig.search !== undefined && tableConfig.search !== null && tableConfig.search.length > 0 && tableConfig.search !== 'undefined' && tableConfig.search !== 'null') {
                setTimeout(() => {
                  searchControl.value = tableConfig.search;
                  searchControl.setAttribute('value', tableConfig.search);
                });
              }
            }
            if (tableConfig.search === undefined || tableConfig.search === null || tableConfig.search.length === 0) {
              await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection, tableConfig.search);
            }
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage, sortField, sortDirection, search);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage, sortField, sortDirection, search);
      }
    })();
  }, [search]);

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="header-title">{t('Sterowniki')}</h4>
              {!isLoading &&
              user !== undefined &&
              user !== null &&
              user.permissions.indexOf("drivers.add") !== -1 ? (
                <Link to={"/drivers/add"} className="btn btn-light mb-2">
                  <i className="mdi mdi-plus-circle me-2"></i>
                  {t("Dodaj nowy sterownik")}
                </Link>
              ) : (
                <></>
              )}
            </div>
            <div className="table-responsive">
              {showTable && configLoaded ? (
                <DataTable
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                  noDataComponent={<div className={"p5"}>{t("Brak sterowników do wyświetlenia")}</div>}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl sterowników na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  paginationDefaultPage={defaultPage}
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedDriverDelete !== null}
                onClose={() => setSelectedDriverDelete(null)}
                onConfirm={() => deleteDriver(selectedDriverDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć sterownik?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
