import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions} from "../lib/request-helper";
import {ShowLoaderContext} from "./show-loader-context";
import {FormInputDriverQuestion} from "../types/forms";
import {DriverQuestion} from '../types/driver-question';
import {SortableUpdate} from '../types/sortable';
import {OrderStatus} from '../types/order-status';

export default function useDriverQuestion() {
  const {setShowLoader} = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/driver-question?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleDriverQuestion = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/driver-question/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<DriverQuestion> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/driver-question/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createDriverQuestion = async (inputData: FormInputDriverQuestion): Promise<DriverQuestion> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/driver-question`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateDriverQuestion = async (id: string, inputData: FormInputDriverQuestion): Promise<DriverQuestion> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/driver-question/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateDriverQuestionPosition = async (items: Array<SortableUpdate>): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/driver-question/position`, {items}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteDriverQuestion = async (id: string): Promise<DriverQuestion> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/driver-question/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleDriverQuestion,
    getById,
    createDriverQuestion,
    updateDriverQuestion,
    updateDriverQuestionPosition,
    forceDeleteDriverQuestion,
  };
}
