import React, {useContext, useState} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Order} from '../../types/order';
import useOrders from '../../hooks/use-orders';
import OrderViewDetails from '../../components/layout/order-view-details';

export default function OrdersView() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const params = useParams();
  const {getById} = useOrders();
  const [order, setOrder] = useState<Order | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setOrder(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "orders";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Zlecenia"), path: "/orders"},
      {path: null, label: t("Szczegóły")},
    ];
    setLayout({...layout});
  }, []);

  return order !== undefined && order !== null ? (
    <OrderViewDetails id={params.id || null} order={order} />
  ) : (
    <></>
  );
}
