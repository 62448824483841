import React, {useContext, useState} from "react";
import {LayoutContext} from "../../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useDriverQuestion from '../../../hooks/use-driver-question';
import {DriverQuestion} from '../../../types/driver-question';
import DriverQuestionForm from '../../../components/forms/driver-question-form';

export default function DriverQuestionEdit() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const params = useParams();
  const {getById} = useDriverQuestion();
  const [driverQuestion, setDriverQuestion] = useState<DriverQuestion | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setDriverQuestion(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {
        label: t("Pytania do sterowników"),
        path: "/settings/driver-question",
      },
      {path: null, label: t("Edytuj")},
    ];
    setLayout({...layout});
  }, []);

  return driverQuestion !== undefined && driverQuestion !== null ? (
    <DriverQuestionForm id={params.id || null} driverQuestion={driverQuestion} />
  ) : (
    <></>
  );
}
