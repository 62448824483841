import React, {useContext, useState} from "react";
import {LayoutContext} from "../../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {AppConfig} from '../../../types/app-config';
import useAppConfig from '../../../hooks/use-app-config';
import AppConfigForm from '../../../components/forms/app-config-form';

export default function AppConfigEdit() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const {getAppConfig} = useAppConfig();
  const [appConfig, setAppConfig] = useState<AppConfig | null>();

  React.useEffect(() => {
    (async () => {
      try {
        const item = await getAppConfig();
        setAppConfig(item);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {
        label: t("Konfiguracja"),
        path: "/settings",
      },
      {path: null, label: t("Edytuj")},
    ];
    setLayout({...layout});
  }, []);

  return appConfig !== undefined && appConfig !== null ? (
    <AppConfigForm appConfig={appConfig} />
  ) : (
    <></>
  );
}
