import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {OrderStatus} from '../../types/order-status';
import useOrderStatus from '../../hooks/use-order-status';
import {FormInputOrderStatus} from '../../types/forms';
import {Form} from 'react-bootstrap';

interface OrderStatusFormProps {
  orderStatus?: OrderStatus | null;
  id?: string | null;
}

export default function OrderStatusForm({id = null, orderStatus = null}: OrderStatusFormProps) {
  const {t} = useTranslation();
  const {createOrderStatus, updateOrderStatus} = useOrderStatus();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputOrderStatus>();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    if (orderStatus !== undefined && orderStatus !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputOrderStatus);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputOrderStatus, orderStatus[k as keyof OrderStatus]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updateOrderStatus(id, data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/order-status");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createOrderStatus(data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/order-status");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj status zlecenia" : "Dodaj status zlecenia")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Nazwa")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", {required: true})}
                      defaultValue={''}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="colorBackground" className="form-label">
                      {t("Kolor tła")}
                    </label>
                    <Form.Control
                      type={'color'}
                      className={"form-control form-control-lg w-100" + (errors && errors.colorBackground !== undefined ? " has-error" : "")}
                      id="colorBackground"
                      {...register("colorBackground", {required: true})}
                      defaultValue={orderStatus !== undefined && orderStatus !== null ? orderStatus.colorBackground : '#000000'}
                    />
                    {errors && errors.colorBackground !== undefined ? (
                      <span className={"error-message"}>{t(errors.colorBackground?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="colorFont" className="form-label">
                      {t("Kolor tekstu")}
                    </label>
                    <Form.Control
                      type={'color'}
                      className={"form-control form-control-lg w-100" + (errors && errors.colorFont !== undefined ? " has-error" : "")}
                      id="colorFont"
                      {...register("colorFont", {required: true})}
                      defaultValue={orderStatus !== undefined && orderStatus !== null ? orderStatus.colorFont : '#FFFFFF'}
                    />
                    {errors && errors.colorFont !== undefined ? (
                      <span className={"error-message"}>{t(errors.colorFont?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings/order-status"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
