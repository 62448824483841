import React, {useContext, useState} from "react";
import {LayoutContext} from "../../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import OrderStatusForm from '../../../components/forms/order-status-form';
import useOrderStatus from '../../../hooks/use-order-status';
import {OrderStatus} from '../../../types/order-status';

export default function OrderStatusEdit() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const params = useParams();
  const {getById} = useOrderStatus();
  const [orderStatus, setOrderStatus] = useState<OrderStatus | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setOrderStatus(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Ustawienia"), path: "/settings"},
      {
        label: t("Statusy zleceń"),
        path: "/settings/order-status",
      },
      {path: null, label: t("Edytuj")},
    ];
    setLayout({...layout});
  }, []);

  return orderStatus !== undefined && orderStatus !== null ? (
    <OrderStatusForm id={params.id || null} orderStatus={orderStatus} />
  ) : (
    <></>
  );
}
