import React, {useContext} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import OrderForm from '../../components/forms/order-form';

export default function OrdersAdd() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "orders";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Zlecenia"), path: "/orders"},
      {path: null, label: t("Dodaj")},
    ];
    setLayout({...layout});
  }, []);

  return <OrderForm />;
}
