import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions} from "../lib/request-helper";
import {ShowLoaderContext} from "./show-loader-context";
import {FormInputOrderStatus} from "../types/forms";
import {OrderStatus} from '../types/order-status';
import {SortableUpdate} from '../types/sortable';

export default function useOrderStatus() {
  const {setShowLoader} = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/order-status?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleOrderStatus = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order-status/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/order-status/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createOrderStatus = async (inputData: FormInputOrderStatus): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/order-status`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateOrderStatus = async (id: string, inputData: FormInputOrderStatus): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/order-status/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateOrderStatusPosition = async (items: Array<SortableUpdate>): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/order-status/position`, {items}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteOrderStatus = async (id: string): Promise<OrderStatus> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/order-status/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleOrderStatus,
    getById,
    createOrderStatus,
    updateOrderStatus,
    updateOrderStatusPosition,
    forceDeleteOrderStatus,
  };
}
