import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FormInputOrderSendBackDrivers} from "../../types/forms";
import {Link, useNavigate} from "react-router-dom";
import {generate} from "../../lib/random-id-lib";
import {ToastsContext} from "../../hooks/toasts-context";
import {Order} from '../../types/order';
import useOrders from '../../hooks/use-orders';
import {Driver} from '../../types/driver';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import useDrivers from '../../hooks/use-drivers';
import usePartners from '../../hooks/use-partners';
import {Partner} from '../../types/partner';
import {DriverStatus} from '../../types/driver-status';
import useDriverStatus from '../../hooks/use-driver-status';
import {FormCheck} from 'react-bootstrap';
import {OrderStatus} from '../../types/order-status';
import useOrderStatus from '../../hooks/use-order-status';

interface OrderFormProps {
  order?: Order | null;
  id?: string | null;
}

export default function DriversSendBackForm({id = null, order = null}: OrderFormProps) {
  const {t} = useTranslation();
  const {createOrderSendDriversBack, updateOrder, createOrderNumber, listingOrdersForSendBack} = useOrders();
  const {listing} = useDrivers();
  const {listingSimpleOrderStatus} = useOrderStatus();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputOrderSendBackDrivers>();
  const {toasts, setToasts} = useContext(ToastsContext);
  const {listingSimplePartners} = usePartners();
  const {listingSimpleDriverStatus} = useDriverStatus();
  const [drivers, setDrivers] = useState<Array<Driver>>([]);
  const [partner, setPartner] = useState<Partner | null>(null);
  const [partners, setPartners] = useState<Array<Partner>>([]);
  const [partnerOptions, setPartnerOptions] = useState<Array<Partner>>([]);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orders, setOrders] = useState<Array<Order>>([]);
  const [driverStatuses, setDriverStatuses] = useState<Array<DriverStatus>>([]);
  const [orderStatuses, setOrderStatuses] = useState<Array<OrderStatus>>([]);
  const [orderStatusesOptions, setOrderStatusesOptions] = useState<Array<OrderStatus>>([]);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputOrderSendBackDrivers);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputOrder, order[k as keyof Order]);
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleOrderStatus();
        setOrderStatuses([...rows]);
        setOrderStatusesOptions([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (id !== undefined && id !== null) {
      if (order !== undefined && order !== null) {
        setOrderNumber(order.orderNumber);
      }
    } else {
      (async () => {
        try {
          const result: any = await createOrderNumber();
          if (result.orderNumber !== undefined && result.orderNumber !== null) {
            setOrderNumber(result.orderNumber);
          }
        } catch (e: any) {
          console.log(e);
        }
      })();
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleDriverStatus();
        setDriverStatuses([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
        setDrivers([...order.drivers]);
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimplePartners();
        setPartners([...rows]);
        setPartnerOptions([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      setValue("partnerDestination", order.partnerDestination!);
      setPartner(order.PartnerDestination!);
      (async () => {
        try {
          const rows = await listingOrdersForSendBack(order.partnerDestination!, order!.id!);
          if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
            for (let i = 0; i < order.drivers.length; i++) {
              const o: any = order.drivers[i];
              if (o.relatedOriginalOrder !== undefined && o.relatedOriginalOrder !== null) {
                const index = rows.findIndex((it: Order) => it.id === o.relatedOriginalOrder);
                if (index > -1) {
                  if (rows[index].drivers !== undefined && rows[index].drivers !== null) {
                    const dIndex = rows[index]!.drivers!.findIndex((x: any) => x.id === o.id && !x.savedBefore);
                    if (dIndex > -1) {
                      rows[index]!.drivers![dIndex].toUpload = true;
                      rows[index]!.drivers![dIndex].savedBefore = true;
                      rows[index]!.drivers![dIndex].savedBeforeId = o.orderDriverId;
                      rows[index]!.drivers![dIndex].driverStatus = o.driverStatus;
                    }
                  }
                }
              }
            }
          }
          setOrders([...rows]);
        } catch (e: any) {
          console.log(e);
        }
      })();
    }
  }, []);

  React.useEffect(() => {
    if (order !== undefined && order !== null) {
      if (driverStatuses !== undefined && driverStatuses !== null && driverStatuses.length > 0) {
        if (order.drivers !== undefined && order.drivers !== null && order.drivers.length > 0) {
          if (orders !== undefined && orders !== null && orders.length > 0) {
            for (let index = 0; index < orders.length; index++) {
              for (let dIndex = 0; dIndex < orders[index]!.drivers!.length; dIndex++) {
                if (orders[index]!.drivers![dIndex]!.savedBefore) {
                  try {
                    if (orders[index]!.drivers![dIndex]!.driverStatus !== undefined && orders[index]!.drivers![dIndex]!.driverStatus !== null) {
                      (document.querySelector(`#DriverStatusBeforeSendBack_${index}_${dIndex!}`) as HTMLSelectElement).value = orders[index]!.drivers![dIndex]!.driverStatus!;
                    }
                  } catch (e: any) {
                    console.log(e);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [driverStatuses, orders]);

  const handleSearchOrderStatus = async (query: string) => {
    const rows = orderStatuses.filter((it) => it.title?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setOrderStatuses([...rows]);
  };

  const changedOrderStatus = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("currentStatus", (e[0] as OrderStatus).id!!);
    }
  };

  const handleLogin = async (data: any) => {
    const items: Array<any> = [];
    orders.forEach(o => {
      if (o.drivers !== undefined && o.drivers !== null && o.drivers.length > 0) {
        o.drivers.forEach(it => {
          if (it.toUpload || it.toDelete) {
            const item: any = {
              id: it.id,
              order: o.id!,
              driverStatus: it.driverStatus,
              toUpload: it.toUpload,
              toDelete: it.toDelete
            };
            if (it.savedBeforeId !== undefined && it.savedBeforeId !== null) {
              item.orderDriverId = it.savedBeforeId;
            }
            items.push(item);
          }
        });
      }
    });

    data.drivers = items;
    data.orderNumber = orderNumber;

    if (id !== undefined && id !== null) {
      try {
        await updateOrder(id, data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/orders");
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await createOrderSendDriversBack(data);
        toasts.push({
          title: t("Sukces"),
          content: t("Dane zapisane"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
        navigate("/orders");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  const filterBy = () => true;

  const handleSearchPartner = async (query: string) => {
    const rows = partners.filter((it) => it.city?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setPartnerOptions([...rows]);
  };

  const changedPartner = async (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("partnerDestination", (e[0] as Partner).id!!);
      setPartner(e[0] as Partner);
      try {
        const rows = await listingOrdersForSendBack((e[0] as Partner).id!!, order !== undefined && order !== null ? order!.id! : '');
        setOrders([...rows]);
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const changedDriverStatus = (event: any, index: number, dIndex: number) => {
    orders[index]!.drivers![dIndex].driverStatus = event.target.value;
    setOrders([...orders]);
  }

  const changedSelection = (e: any, index: number, dIndex: number) => {
    if (e.target.checked) {
      orders[index]!.drivers![dIndex].toUpload = true;
    } else {
      orders[index]!.drivers![dIndex].toUpload = false;
    }
    setOrders([...orders])
  }

  const deleteDriver = (index: number, dIndex: number) => {
    orders[index]!.drivers![dIndex].toDelete = true;
    orders[index]!.drivers![dIndex].toUpload = false;
    setOrders([...orders]);
  }

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="header-title">
                    <span>{t('Zlecenie numer:')}</span>
                    <span> {orderNumber}</span>
                  </h4>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="form-group position-relative">
                      <label className="form-label">{t("Oddział")}</label>
                      <AsyncTypeahead
                        defaultInputValue={order !== undefined && order !== null ? order.PartnerDestination?.title : ""}
                        filterBy={filterBy}
                        id="async-car-brand"
                        isLoading={false}
                        labelKey="title"
                        minLength={0}
                        onSearch={handleSearchPartner}
                        onChange={(e) => changedPartner(e)}
                        options={partnerOptions}
                        placeholder={t("Wybierz oddział")}
                        inputProps={{className: 'form-control-lg'}}
                        renderMenuItemChildren={(option: any) => (
                          <>
                            <span>{option.title}</span>
                          </>
                        )}
                      />
                      {errors && errors.partnerDestination !== undefined ? (
                        <span className={"error-message"}>{t(errors.partnerDestination?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="deliveryMethod" className="form-label">
                        {t("Metoda wysyłki")}
                      </label>
                      <select
                        className={"form-control form-control-lg" + (errors && errors.deliveryMethod !== undefined ? " has-error" : "")}
                        id="deliveryMethod"
                        {...register("deliveryMethod")}
                        defaultValue={""}
                      >
                        <option value={'self'}>{t('Dostawa własna')}</option>
                        <option value={'dpd'}>{t('Kurier DPD')}</option>
                      </select>
                      {errors && errors.deliveryMethod !== undefined ? (
                        <span className={"error-message"}>{t(errors.deliveryMethod?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {order !== undefined && order !== null ? (
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Status zlecenia")}</label>
                        <AsyncTypeahead
                          defaultInputValue={order !== undefined && order !== null ? order.CurrentStatus?.title : ""}
                          filterBy={filterBy}
                          id="async-car-brand"
                          isLoading={false}
                          labelKey="title"
                          minLength={0}
                          onSearch={handleSearchOrderStatus}
                          onChange={(e) => changedOrderStatus(e)}
                          options={orderStatusesOptions}
                          placeholder={t("Status zlecenia")}
                          inputProps={{className: 'form-control-lg'}}
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option.title}</span>
                            </>
                          )}
                        />
                        {errors && errors.currentStatus !== undefined ? (
                          <span className={"error-message"}>{t(errors.currentStatus?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ) : <></>}

                {orders.length > 0 ? (
                  <div className={'mb-4'}>
                    {orders.map((it, index) => (
                      <div className="row" key={`OrderItem_${index}`}>
                        <div className="col-12 col-md-12">
                          <h5>{t('Numer zlecenia')}: <b>{it.orderNumber}</b></h5>
                          {it.drivers !== undefined && it.drivers !== null && it.drivers.length > 0 ? (
                            <div className={'mb-3 ms-4 dropzone-previews'}>
                              {it.drivers.map((d, dIndex) => (
                                <div className={'d-flex align-items-center'} key={`DriverSendBack_${dIndex}`}>
                                  <div
                                    className={`flex-1 card mt-1 mb-0 me-3 shadow-none border${!d.toUpload || d.toDelete ? ' disabled' : ''}`}
                                    key={`DriverBeforeSendBack${index}`}>
                                    <div className="p-2">
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div className="avatar-sm">
                                          <span className="avatar-title rounded">
                                            <i className="mdi mdi-flash-outline font-16"></i>
                                          </span>
                                          </div>
                                        </div>
                                        <div className="col ps-0">
                                          <div className={'d-flex flex-row align-items-center'}>
                                            <div className={'pe-5'}>
                                              <a className="file_name text-muted fw-bold" data-dz-name="">
                                                {d.driverType}
                                                {d.driverNumber !== undefined && d.driverNumber !== null && d.driverNumber.length > 0 ? ` / ${d.driverNumber}` : ''}
                                                {d.driverCode !== undefined && d.driverCode !== null && d.driverCode.length > 0 ? ` / ${d.driverCode}` : ''}
                                              </a>
                                              <p className="mb-0 file_name" data-dz-size="">
                                                <strong>{d.CarBrand?.title} {d.CarModel?.title}</strong>
                                              </p>
                                            </div>
                                            <div>
                                              {d.year !== undefined && d.year !== null && d.year > 0 ? (
                                                <span>{t('Rok')}: {d.year}</span>
                                              ) : <></>}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-auto d-flex align-items-center">

                                          <select id={`DriverStatusBeforeSendBack_${index}_${dIndex!}`}
                                                  onChange={(e: any) => changedDriverStatus(e, index, dIndex)}
                                                  disabled={!d.toUpload}
                                                  className={'form-control form-control-lg select-with-arrow me-5'}>
                                            {driverStatuses.map((driverStatus, statusIndex) => (
                                              <option
                                                key={`DriverStatusSelect_${index}_${statusIndex}`}
                                                value={driverStatus.id!}>{driverStatus.title}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={'driver-select-holder'}>
                                    {id === undefined || id === null || !d.savedBefore ? (
                                      <FormCheck
                                        onChange={(e) => changedSelection(e, index, dIndex)}
                                        label={t('Wybierz do wysyłki')}
                                        type={'switch'}
                                        id={`FormCheckboxDriver_${index}_${dIndex}`}
                                      />
                                    ) : (
                                      !d.toDelete ? (
                                        <a className={'btn btn-outline-danger'}
                                           onClick={() => deleteDriver(index, dIndex)}>
                                          {t('Usuń ze zlecenia')}
                                        </a>
                                      ) : <></>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={'mb-3 ms-4'}>{t('Brak sterowników w zleceniu')}</div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : <></>}

                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="title" className="form-label">
                        {t("Dodatkowe informacje do zlecenia")}
                      </label>
                      <textarea
                        className={"form-control form-control-lg" + (errors && errors.info !== undefined ? " has-error" : "")}
                        id="info"
                        rows={6}
                        {...register("info", {required: false})}
                        defaultValue={""}
                      ></textarea>
                      {errors && errors.info !== undefined ? (
                        <span className={"error-message"}>{t(errors.info?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer p-4 text-muted">
                <div className="row">
                  <div className="col-12 text-end">
                    <Link className="btn btn-outline-primary ms-3" to={"/orders"}>
                      {t("Anuluj")}
                    </Link>
                    {id !== undefined && id !== null ? (
                      <button className="btn btn-outline-primary ms-3">{t("Zapisz zlecenie")}</button>
                    ) : (
                      <button className="btn btn-outline-primary ms-3">{t("Dodaj zlecenie")}</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
