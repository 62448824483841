import React, {useState} from "react";
import {Driver} from '../../types/driver';
import {useTranslation} from 'react-i18next';
import {OrderHistory} from '../../types/order-history';
import StatusPreview from './status-preview';
import Moment from 'react-moment';

interface OrderHistoryProps {
  history: Array<OrderHistory>;
}

export default function OrderViewHistory({history = []}: OrderHistoryProps) {
  const {t} = useTranslation();

  return (
    <>
      {history.length > 0 ? (
        <div className="row mb-5">
          <div className="col-12">
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className={'d-flex align-items-center'}>
                    <h4 className="header-title mb-0">
                      <span>{t('Historia zlecenia')}</span>
                    </h4>
                  </div>
                </div>
                {history.map((h, index) => (
                  <div className={`card mt-1 mb-0 shadow-none border`} key={`OrderHistory${index}`}>
                    <div className="p-2">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="avatar-sm">
                              <span className="avatar-title rounded">
                                <i className="mdi mdi-history font-16"></i>
                              </span>
                          </div>
                        </div>
                        <div className="col ps-0">
                          <div>
                            <span>{t('Status zlecenia')}:</span>
                            <a className="file_name text-muted fw-bold ms-2" data-dz-name="">
                              {h.OrderStatus?.title}
                            </a>
                          </div>
                          <p className="mb-0 file_name" data-dz-size="">
                            <span>{t('Użytkownik')}:</span>
                            <strong className={'ms-2'}>{h.Employee?.firstName} {h.Employee?.lastName}</strong>
                            <span className={'ms-2'}>({h.Partner?.title}, {h.Partner?.city}, {h.Partner?.street})</span>
                          </p>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                          <Moment format={'YYYY-MM-DD HH:mm'}>{h.createdAt}</Moment>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : <></>}
    </>
  );
}
