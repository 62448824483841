import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import {Driver, DriverGetByIdResponse} from "../types/driver";
import { FormInputDriver } from "../types/forms";

export default function useDrivers() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/driver?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<DriverGetByIdResponse> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/driver/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createDriver = async (inputData: FormInputDriver): Promise<Driver> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/driver`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateDriver = async (id: string, inputData: FormInputDriver | { active: boolean }): Promise<Driver> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/driver/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateDriverAnswers = async (id: string, inputData: any): Promise<Driver> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/driver/answers/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteDriver = async (id: number | string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/driver/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteDriverFile = async (driverId: string, driverFileId: string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/driver/${driverId}/file/${driverFileId}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteDriverAnswerFile = async (driverId: string, driverQuestionId: string, driverAnswerFileId: string, orderId: string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/driver/${driverId}/question/${driverQuestionId}/order/${orderId}/file/${driverAnswerFileId}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    getById,
    createDriver,
    updateDriver,
    updateDriverAnswers,
    forceDeleteDriver,
    forceDeleteDriverFile,
    forceDeleteDriverAnswerFile,
  };
}
