import React, { useContext } from "react";
import { LayoutContext } from "../../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import CarModelForm from "../../../components/forms/car-model-form";

export default function CarModelAdd() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.showSearch = false;
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      {
        label: t("Marka pojazdu"),
        path: "/settings/car/brand",
      },
      { path: null, label: t("Dodaj") },
    ];
    setLayout({ ...layout });
  }, []);

  return <CarModelForm />;
}
