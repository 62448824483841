import React, { useContext, useState } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import EmployeeForm from "../../components/forms/employee-form";
import { useParams } from "react-router-dom";
import useEmployee from "../../hooks/use-employee";
import { Employee } from "../../types/employee";

export default function EmployeeEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useEmployee();
  const [employee, setEmployee] = useState<Employee | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setEmployee(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "employee";
    layout.showSearch = false;
    layout.breadcrumbs = [
      { label: t("Użytkownicy"), path: "/employee" },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return employee !== undefined && employee !== null ? (
    <EmployeeForm id={params.id || null} employee={employee} />
  ) : (
    <></>
  );
}
