import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import { Link } from "react-router-dom";
import { Partner } from "../../types/partner";
import usePartners from "../../hooks/use-partners";
import DataTable from "react-data-table-component";
import { Dropdown } from "react-bootstrap";
import Confirmation from "../../components/layout/confirmation";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";
import { UserContext } from "../../hooks/user-context";

export default function PartnersList() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Partner>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const { listing, updatePartner, forceDeletePartner } = usePartners();
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
  const [selectedPartnerDelete, setSelectedPartnerDelete] = useState<Partner | null>(null);
  const { toasts, setToasts } = useContext(ToastsContext);
  const { user, isLoading } = useContext(UserContext);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  React.useEffect(() => {
    layout.sideMenu = "partners";
    layout.showSearch = true;
    layout.breadcrumbs = [{ path: null, label: t("Oddziały") }];
    setLayout({ ...layout });
  }, []);

  React.useEffect(() => {
    if (configLoaded) {
      if (layout.topSearch !== search) {
        setSearch(layout.topSearch);
      }
    }
  }, [layout.topSearch]);

  React.useEffect(() => {
    const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
    if (searchControl !== undefined && searchControl !== null) {
      if (search !== undefined && search !== null && search.length > 0 && search !== 'undefined' && search !== 'null') {
        setTimeout(() => {
          searchControl.value = search;
          searchControl.setAttribute('value', search);
        });
      }
    }
  }, [search, layout.showSearch]);

  const columns: Array<any> = [
    {
      name: t("Nazwa"),
      sortable: true,
      key: "title",
      selector: (row: any) => <span>{row.title}</span>,
      right: false,
    },
    {
      name: t("Zlecenia wysłane"),
      sortable: true,
      key: "orderSendCounter",
      selector: (row: any) => <span className={'badge-counter'}>{row.orderSendCounter || 0}</span>,
      right: false,
    },
    {
      name: t("Miejscowość"),
      sortable: true,
      key: "city",
      selector: (row: any) => <span>{row.city}</span>,
      right: false,
    },
    {
      name: t("Ulica i numer"),
      sortable: true,
      key: "street",
      selector: (row: any) => <span>{row.street}</span>,
      right: false,
    },
    // {
    //   name: t("Logowanie"),
    //   sortable: false,
    //   key: "websiteUrl",
    //   selector: (row: any) => <a target={'_blank'} href={row.websiteUrl}>{row.websiteUrl}</a>,
    //   right: false,
    // },
    {
      name: "",
      selector: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={"div"}>
              <Link to={`/partners/edit/${row.id}`}>{t("Edytuj")}</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedPartnerDelete(row)}>{t("Usuń")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      right: true,
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null,
    sSearch: string | null = null
  ) => {
    try {
      localStorage.setItem('table_partners', JSON.stringify({
        page, currentPerPage, sField, sDirection, search
      }));
      const result = await listing(page, currentPerPage, sSearch === undefined || sSearch === null ? search : sSearch, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const deletePartner = async (id: any) => {
    if (selectedPartnerDelete !== undefined && selectedPartnerDelete !== null) {
      try {
        await forceDeletePartner(selectedPartnerDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Oddział usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage);
    }

    setSelectedPartnerDelete(null);
  };

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem('table_partners');
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            setSearch(tableConfig.search);
            const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
            if (searchControl !== undefined && searchControl !== null) {
              if (tableConfig.search !== undefined && tableConfig.search !== null && tableConfig.search.length > 0 && tableConfig.search !== 'undefined' && tableConfig.search !== 'null') {
                setTimeout(() => {
                  searchControl.value = tableConfig.search;
                  searchControl.setAttribute('value', tableConfig.search);
                });
              }
            }
            if (tableConfig.search === undefined || tableConfig.search === null || tableConfig.search.length === 0) {
              await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection, tableConfig.search);
            }
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage, sortField, sortDirection, search);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage, sortField, sortDirection, search);
      }
    })();
  }, [search]);

  const handleSort = async (column: any, direction: any) => {
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="header-title">{t('Oddziały')}</h4>
              {!isLoading &&
              user !== undefined &&
              user !== null &&
              user.permissions.indexOf("partners.add") !== -1 ? (
                <Link to={"/partners/add"} className="btn btn-light mb-2">
                  <i className="mdi mdi-plus-circle me-2"></i>
                  {t("Dodaj nowy oddział")}
                </Link>
              ) : (
                <></>
              )}
            </div>
            <div className="table-responsive">
              {showTable ? (
                <DataTable
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                  noDataComponent={<div className={"p5"}>{t("Brak oddziałów do wyświetlenia")}</div>}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl oddziałów na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  paginationDefaultPage={defaultPage}
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedPartnerDelete !== null}
                onClose={() => setSelectedPartnerDelete(null)}
                onConfirm={() => deletePartner(selectedPartnerDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć oddział?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
