import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { Partner } from "../types/partner";
import { FormInputPartner } from "../types/forms";

export default function usePartners() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/partner?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimplePartners = async (query: string = ''): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/partner/simple?search=${query}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<Partner> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/partner/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createPartner = async (inputData: FormInputPartner): Promise<Partner> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/partner`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updatePartner = async (id: string, inputData: FormInputPartner | { active: boolean }): Promise<Partner> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/partner/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeletePartner = async (id: number | string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/partner/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimplePartners,
    getById,
    createPartner,
    updatePartner,
    forceDeletePartner,
  };
}
