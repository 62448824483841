import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../hooks/layout-context";
import {Link} from "react-router-dom";
import {Order} from "../../types/order";
import useOrders from "../../hooks/use-orders";
import DataTable from "react-data-table-component";
import {Dropdown, Modal} from "react-bootstrap";
import Confirmation from "../../components/layout/confirmation";
import {generate} from "../../lib/random-id-lib";
import {ToastsContext} from "../../hooks/toasts-context";
import {UserContext} from "../../hooks/user-context";
import Moment from 'react-moment';
import {AppConfig} from '../../types/app-config';
import useAppConfig from '../../hooks/use-app-config';
import StatusPreview from '../../components/layout/status-preview';
import DeliveryMethod from '../../components/layout/delivery-method';

export default function OrdersListArchived() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Order>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const {listingArchived, undoDeleteOrder} = useOrders();
  const {getAppConfig} = useAppConfig();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedOrderDelete, setSelectedOrderDelete] = useState<Order | null>(null);
  const {toasts, setToasts} = useContext(ToastsContext);
  const {user, isLoading} = useContext(UserContext);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  React.useEffect(() => {
    layout.sideMenu = "orders";
    layout.showSearch = true;
    layout.breadcrumbs = [
      {path: '/orders', label: t("Zlecenia")},
      {path: null, label: t("Archiwum")}
    ];
    setLayout({...layout});
  }, []);

  React.useEffect(() => {
    if (configLoaded) {
      if (layout.topSearch !== search) {
        setSearch(layout.topSearch);
      }
    }
  }, [layout.topSearch]);

  React.useEffect(() => {
    (async () => {
      try {
        const cfg = await getAppConfig();
        setAppConfig(cfg);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
    if (searchControl !== undefined && searchControl !== null) {
      if (search !== undefined && search !== null && search.length > 0 && search !== 'undefined' && search !== 'null') {
        setTimeout(() => {
          searchControl.value = search;
          searchControl.setAttribute('value', search);
        });
      }
    }
  }, [search, layout.showSearch]);

  const columns: Array<any> = [
    {
      name: t("Data utworzenia"),
      sortable: true,
      key: "orderNumber",
      selector: (row: any) => <span><Moment format={'YYYY-MM-DD'}>{row.createdAt}</Moment></span>,
      right: false,
    },
    {
      name: t("Sterowniki"),
      sortable: false,
      key: "drivers",
      selector: (row: any) => (
        <div className={'table-listing-holder'}>
          {row.drivers !== undefined && row.drivers !== null && row.drivers.length > 0 ? (
            <div>
              {row.drivers.map((d: any, dIndex: number) => (
                <p className={'order-number-value'} key={`driverShort_${row.id!}_${dIndex}`}>{d.driverNumber}</p>
              ))}
            </div>
          ) : <></>}
        </div>
      ),
      right: false,
    },
    {
      name: t("Status"),
      sortable: true,
      key: "currentStatus",
      selector: (row: any) =>
        <StatusPreview
          title={row.CurrentStatus!.title}
          colorBackground={row.CurrentStatus!.colorBackground}
          colorFont={row.CurrentStatus!.colorFont}
        />,
      right: false,
    },
    {
      name: t("Metoda wysyłki"),
      sortable: true,
      key: "currentStatus",
      selector: (row: any) => <DeliveryMethod deliveryMethod={row.deliveryMethod} />,
      right: false,
    },
    {
      name: t("Liczba sterowników"),
      sortable: false,
      key: "driversCounter",
      selector: (row: any) => <span className={'badge-counter'}>{row.driversCounter || 0}</span>,
      right: false,
    },
    {
      name: t("Oddział wysyłający"),
      sortable: true,
      key: "partnerSource",
      selector: (row: any) => <span>{row.PartnerSource?.title}</span>,
      right: false,
    },
    {
      name: t("Oddział docelowy"),
      sortable: true,
      key: "partnerDestination",
      selector: (row: any) => <span>{row.PartnerDestination?.title}</span>,
      right: false,
    },
    {
      name: "",
      selector: (row: any) => (
        <div className={'d-flex align-items-center'}>
          <Dropdown>
            <Dropdown.Toggle as={"a"}>
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={"div"}>
                <Link to={`/orders/view/${row.id}`}>{t("Szczegóły")}</Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedOrderDelete(row)}
                             className={'text-success'}>{t("Przywróć z archiwum")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      right: true,
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null,
    sSearch: string | null = null,
    sFilters: any = null
  ) => {
    try {
      localStorage.setItem('table_orders_archived', JSON.stringify({
        page, currentPerPage, sField, sDirection, search, filters: sFilters
      }));
      const result = await listingArchived(page, currentPerPage, sSearch === undefined || sSearch === null ? search : sSearch, sField, sDirection, sFilters);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const deleteOrder = async (id: any) => {
    if (selectedOrderDelete !== undefined && selectedOrderDelete !== null) {
      try {
        await undoDeleteOrder(selectedOrderDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Zlecenie przywrócone"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się przywrócić zlecenia"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage);
    }

    setSelectedOrderDelete(null);
  };

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem('table_orders_archived');
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            setSearch(tableConfig.search);
            const searchControl = (document.querySelector('#top-search') as HTMLInputElement);
            if (searchControl !== undefined && searchControl !== null) {
              if (tableConfig.search !== undefined && tableConfig.search !== null && tableConfig.search.length > 0 && tableConfig.search !== 'undefined' && tableConfig.search !== 'null') {
                setTimeout(() => {
                  searchControl.value = tableConfig.search;
                  searchControl.setAttribute('value', tableConfig.search);
                });
              }
            }
            if (tableConfig.search === undefined || tableConfig.search === null || tableConfig.search.length === 0) {
              await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection, tableConfig.search);
            }
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage, sortField, sortDirection, search);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage, sortField, sortDirection, search);
      }
    })();
  }, [search]);

  const handleSort = async (column: any, direction: any) => {
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction, search);
    setLoading(false);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">{t('Zlecenia')} - {t('Archiwum')}</h4>
              </div>
              <div className="table-responsive">
                {showTable ? (
                  <DataTable
                    columns={columns}
                    data={rows}
                    striped={false}
                    sortServer
                    onSort={handleSort}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                    noDataComponent={<div className={"p5"}>{t("Brak zleceń do wyświetlenia")}</div>}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Wyświetl zleceń na stronę:"),
                      rangeSeparatorText: t("of"),
                      noRowsPerPage: false,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: t("All"),
                    }}
                    paginationDefaultPage={defaultPage}
                    paginationPerPage={perPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                ) : (
                  <></>
                )}
                <Confirmation
                  showConfirmation={selectedOrderDelete !== null}
                  onClose={() => setSelectedOrderDelete(null)}
                  onConfirm={() => deleteOrder(selectedOrderDelete!!.id)}
                  content={t("Czy na pewno chcesz przywrócić zlecenie z archiwum?")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
