import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {DriverStatus} from '../../types/driver-status';
import useDriverStatus from '../../hooks/use-driver-status';
import {FormInputDriverStatus} from '../../types/forms';
import {Form} from 'react-bootstrap';

interface DriverStatusFormProps {
  driverStatus?: DriverStatus | null;
  id?: string | null;
}

export default function DriverStatusForm({id = null, driverStatus = null}: DriverStatusFormProps) {
  const {t} = useTranslation();
  const {createDriverStatus, updateDriverStatus} = useDriverStatus();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputDriverStatus>();
  const {toasts, setToasts} = useContext(ToastsContext);

  React.useEffect(() => {
    if (driverStatus !== undefined && driverStatus !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputDriverStatus);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputDriverStatus, driverStatus[k as keyof DriverStatus]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updateDriverStatus(id, data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/driver-status");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createDriverStatus(data);
        toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
        setToasts([...toasts]);
        navigate("/settings/driver-status");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj status sterownika" : "Dodaj status sterownika")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Nazwa")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="colorBackground" className="form-label">
                      {t("Kolor tła")}
                    </label>
                    <Form.Control
                      type={'color'}
                      className={"form-control form-control-lg w-100" + (errors && errors.colorBackground !== undefined ? " has-error" : "")}
                      id="colorBackground"
                      {...register("colorBackground", {required: true})}
                      defaultValue={driverStatus !== undefined && driverStatus !== null ? driverStatus.colorBackground : '#000000'}
                    />
                    {errors && errors.colorBackground !== undefined ? (
                      <span className={"error-message"}>{t(errors.colorBackground?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="colorFont" className="form-label">
                      {t("Kolor tekstu")}
                    </label>
                    <Form.Control
                      type={'color'}
                      className={"form-control form-control-lg w-100" + (errors && errors.colorFont !== undefined ? " has-error" : "")}
                      id="colorFont"
                      {...register("colorFont", {required: true})}
                      defaultValue={driverStatus !== undefined && driverStatus !== null ? driverStatus.colorFont : '#FFFFFF'}
                    />
                    {errors && errors.colorFont !== undefined ? (
                      <span className={"error-message"}>{t(errors.colorFont?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings/driver-status"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
