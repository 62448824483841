import React from 'react';

interface DeliveryMethodProps {
  deliveryMethod: string;
}

export default function DeliveryMethod({deliveryMethod = 'self'}: DeliveryMethodProps) {
  return (
    <span
      className={`delivery-badge ${deliveryMethod === 'self' ? 'delivery-badge--self' : ''}${deliveryMethod === 'dpd' ? 'delivery-badge--dpd' : ''}`}>
      {deliveryMethod === 'self' ? 'Dostawa własna' : ''}
      {deliveryMethod === 'dpd' ? 'Kurier DPD' : ''}
    </span>
  )
}
