import React, {Suspense} from "react";
import "./App.scss";
import "./i18n";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import {UserContext} from "./hooks/user-context";
import {ShowLoaderContext} from "./hooks/show-loader-context";
import {LayoutContext} from "./hooks/layout-context";
import {ToastsContext} from "./hooks/toasts-context";
import PrivateRoute from "./pages/private-route";
import Template from "./components/layout/template";
import Login from "./pages/login";
import Home from "./pages/home";
import Landing from "./pages/landing";
import NotFound from "./pages/not-found";
import useShowLoader from "./hooks/use-show-loader";
import useAuth from "./hooks/use-auth";
import useLayout from "./hooks/use-layout";
import ForgetPassword from "./pages/forget-password";
import EmployeeList from "./pages/employee/employee-list";
import EmployeeAdd from "./pages/employee/employee-add";
import EmployeeEdit from "./pages/employee/employee-edit";
import useToasts from "./hooks/use-toasts";
import Settings from "./pages/settings/settings";
import CarTypeList from "./pages/settings/car-type/car-type-list";
import CarTypeAdd from "./pages/settings/car-type/car-type-add";
import CarTypeEdit from "./pages/settings/car-type/car-type-edit";
import CarBrandList from "./pages/settings/car-brand/car-brand-list";
import CarBrandAdd from "./pages/settings/car-brand/car-brand-add";
import CarBrandEdit from "./pages/settings/car-brand/car-brand-edit";
import CarGearBoxList from "./pages/settings/car-gear-box/car-gear-box-list";
import CarGearBoxAdd from "./pages/settings/car-gear-box/car-gear-box-add";
import CarGearBoxEdit from "./pages/settings/car-gear-box/car-gear-box-edit";
import CarModelList from "./pages/settings/car-model/car-model-list";
import CarModelAdd from "./pages/settings/car-model/car-model-add";
import CarModelEdit from "./pages/settings/car-model/car-model-edit";
import MyAccount from "./pages/my-account";
import MissingPermission from "./pages/missing-permission";
import {socket, SocketContext} from "./hooks/socket-context";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PartnersList from './pages/partners/partners-list';
import PartnerAdd from './pages/partners/partner-add';
import PartnerEdit from './pages/partners/partner-edit';
import OrderStatusList from './pages/settings/order-status/order-status-list';
import OrderStatusAdd from './pages/settings/order-status/order-status-add';
import OrderStatusEdit from './pages/settings/order-status/order-status-edit';
import DriverQuestionList from './pages/settings/driver-question/driver-question-list';
import DriverQuestionAdd from './pages/settings/driver-question/driver-question-add';
import DriverQuestionEdit from './pages/settings/driver-question/driver-question-edit';
import OrderStatusSort from './pages/settings/order-status/order-status-sort';
import DriverQuestionSort from './pages/settings/driver-question/driver-question-sort';
import DriversList from './pages/drivers/drivers-list';
import DriversAdd from './pages/drivers/drivers-add';
import DriversEdit from './pages/drivers/drivers-edit';
import AppConfigEdit from './pages/settings/app-config/app-config-edit';
import OrdersList from './pages/orders/orders-list';
import OrdersAdd from './pages/orders/orders-add';
import OrdersEdit from './pages/orders/orders-edit';
import OrdersDriversSendBack from './pages/orders/orders-drivers-send-back';
import DriverStatusEdit from './pages/settings/driver-status/driver-status-edit';
import DriverStatusAdd from './pages/settings/driver-status/driver-status-add';
import DriverStatusSort from './pages/settings/driver-status/driver-status-sort';
import DriverStatusList from './pages/settings/driver-status/driver-status-list';
import OrdersView from './pages/orders/orders-view';
import OrdersListArchived from './pages/orders/orders-list-archived';
import OrdersDpdList from './pages/orders/orders-dpd-list';

function App() {
  const {showLoader, setShowLoader} = useShowLoader(false);
  const {user, setUser, isLoading, setUserContext, getProfile} = useAuth();
  const {layout, setLayout} = useLayout({});
  const {toasts, setToasts} = useToasts([]);

  React.useEffect(() => {
    (async () => {
      try {
        await getProfile();
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (user !== undefined && user !== null) {
      socket.emit("join-room", "all");
    }
  }, [user]);

  return (
    <Suspense fallback="loading">
      <HelmetProvider>
        <Router>
          <UserContext.Provider value={{user, setUser, isLoading, setUserContext}}>
            <ShowLoaderContext.Provider value={{showLoader, setShowLoader}}>
              <LayoutContext.Provider value={{layout, setLayout}}>
                <ToastsContext.Provider value={{toasts, setToasts}}>
                  <SocketContext.Provider value={socket}>
                    <Template>
                      <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/missing-permission" element={<MissingPermission />} />
                        <Route
                          path="/home"
                          element={
                            <PrivateRoute permission={null}>
                              <Home />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/my-account"
                          element={
                            <PrivateRoute permission={null}>
                              <MyAccount />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings"
                          element={
                            <PrivateRoute permission={"settings"}>
                              <Settings />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type/add"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model/add"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-questions"
                          element={
                            <PrivateRoute permission={"settings.driverQuestions"}>
                              <DriverQuestionList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-questions/sort"
                          element={
                            <PrivateRoute permission={"settings.driverQuestions"}>
                              <DriverQuestionSort />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-questions/add"
                          element={
                            <PrivateRoute permission={"settings.driverQuestions"}>
                              <DriverQuestionAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-questions/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.driverQuestions"}>
                              <DriverQuestionEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/order-status"
                          element={
                            <PrivateRoute permission={"settings.orderStatuses"}>
                              <OrderStatusList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/order-status/sort"
                          element={
                            <PrivateRoute permission={"settings.orderStatuses"}>
                              <OrderStatusSort />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/order-status/add"
                          element={
                            <PrivateRoute permission={"settings.orderStatuses"}>
                              <OrderStatusAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/order-status/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.orderStatuses"}>
                              <OrderStatusEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand/add"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box/add"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/app-config"
                          element={
                            <PrivateRoute permission={"settings.appConfig"}>
                              <AppConfigEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee"
                          element={
                            <PrivateRoute permission={"employee"}>
                              <EmployeeList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee/add"
                          element={
                            <PrivateRoute permission={"employee.add"}>
                              <EmployeeAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee/edit/:id"
                          element={
                            <PrivateRoute permission={"employee.add"}>
                              <EmployeeEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/partners"
                          element={
                            <PrivateRoute permission={"partners"}>
                              <PartnersList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/partners/add"
                          element={
                            <PrivateRoute permission={"partners.add"}>
                              <PartnerAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/partners/edit/:id"
                          element={
                            <PrivateRoute permission={"partners.add"}>
                              <PartnerEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/drivers"
                          element={
                            <PrivateRoute permission={"drivers"}>
                              <DriversList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/drivers/add"
                          element={
                            <PrivateRoute permission={"drivers.add"}>
                              <DriversAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/drivers/edit/:id"
                          element={
                            <PrivateRoute permission={"drivers.add"}>
                              <DriversEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders"
                          element={
                            <PrivateRoute permission={"orders"}>
                              <OrdersList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/dpd/list"
                          element={
                            <PrivateRoute permission={"orders"}>
                              <OrdersDpdList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/archived"
                          element={
                            <PrivateRoute permission={"orders.archived"}>
                              <OrdersListArchived />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/add"
                          element={
                            <PrivateRoute permission={"orders.add"}>
                              <OrdersAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/send/drivers/back"
                          element={
                            <PrivateRoute permission={"orders.add"}>
                              <OrdersDriversSendBack />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/edit/:id"
                          element={
                            <PrivateRoute permission={"orders.add"}>
                              <OrdersEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/orders/view/:id"
                          element={
                            <PrivateRoute permission={"orders"}>
                              <OrdersView />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-status"
                          element={
                            <PrivateRoute permission={"settings.driverStatuses"}>
                              <DriverStatusList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-status/sort"
                          element={
                            <PrivateRoute permission={"settings.driverStatuses"}>
                              <DriverStatusSort />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-status/add"
                          element={
                            <PrivateRoute permission={"settings.driverStatuses"}>
                              <DriverStatusAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/driver-status/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.driverStatuses"}>
                              <DriverStatusEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Template>
                  </SocketContext.Provider>
                </ToastsContext.Provider>
              </LayoutContext.Provider>
            </ShowLoaderContext.Provider>
          </UserContext.Provider>
        </Router>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
