import React, { useContext, useState } from "react";
import { LayoutContext } from "../../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CarGearBoxForm from "../../../components/forms/car-gear-box-form";
import useCarGearBox from "../../../hooks/use-car-gear-box";
import { CarGearBox } from "../../../types/car";

export default function CarGearBoxEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useCarGearBox();
  const [carGearBox, setCarGearBox] = useState<CarGearBox | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setCarGearBox(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      {
        label: t("Skrzynia biegów"),
        path: "/settings/car/gear/box",
      },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return carGearBox !== undefined && carGearBox !== null ? (
    <CarGearBoxForm id={params.id || null} carGearBox={carGearBox} />
  ) : (
    <></>
  );
}
