import React from 'react';

interface StatusPreviewProps {
  title: string;
  colorBackground: string;
  colorFont: string;
}

const StatusPreview = ({title, colorFont, colorBackground}: StatusPreviewProps) => {
  return (
    <span className={'status-preview'} style={{backgroundColor: colorBackground, color: colorFont}}>
      {title}
    </span>
  )
}

export default StatusPreview;
