import React, { useContext, useState } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import useProfile from "../../hooks/use-profile";
import { Permission } from "../../types/permission";
import { useForm } from "react-hook-form";
import { FormInputPartner } from "../../types/forms";
import { Partner } from "../../types/partner";
import { Link, useNavigate } from "react-router-dom";
import usePartners from "../../hooks/use-partners";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";
import { UserContext } from "../../hooks/user-context";
import { Client } from "../../types/client";
import useClient from "../../hooks/use-client";

interface PartnerFormProps {
  partner?: Partner | null;
  id?: string | null;
}

export default function PartnerForm({ id = null, partner = null }: PartnerFormProps) {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user, setUser } = useContext(UserContext);
  const [client, setClient] = useState<Client | null>(null);
  const { getPermissions } = useProfile();
  const { getById } = useClient();
  const { createPartner, updatePartner } = usePartners();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<Array<Permission>>([]);
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputPartner>();
  const { toasts, setToasts } = useContext(ToastsContext);
  const [avatar, setAvatar] = useState<any>(null);

  React.useEffect(() => {
    let clientId: string | null = null;
    if (partner !== undefined && partner !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputPartner);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputPartner, partner[k as keyof Partner]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updatePartner(id, data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        navigate("/partners");
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await createPartner(data);
        toasts.push({
          title: t("Sukces"),
          content: t("Dane zapisane"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
        navigate("/partners");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="header-title">
                    {t(id !== undefined && id !== null ? "Edytuj oddział" : "Dodaj oddział")}
                  </h4>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="title" className="form-label">
                        {t("Nazwa oddziału")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                        id="title"
                        {...register("title", { required: true })}
                        defaultValue={""}
                      />
                      {errors && errors.title !== undefined ? (
                        <span className={"error-message"}>{t(errors.title?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="titleShort" className="form-label">
                        {t("Skrócona nazwa oddziału")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.titleShort !== undefined ? " has-error" : "")}
                        id="titleShort"
                        {...register("titleShort", { required: false })}
                        defaultValue={""}
                      />
                      {errors && errors.titleShort !== undefined ? (
                        <span className={"error-message"}>{t(errors.titleShort?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="street" className="form-label">
                        {t("Ulica i numer")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.street !== undefined ? " has-error" : "")}
                        id="street"
                        {...register("street", { required: true })}
                        defaultValue={""}
                      />
                      {errors && errors.street !== undefined ? (
                        <span className={"error-message"}>{t(errors.street?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-2 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="zip" className="form-label">
                        {t("Kod pocztowy")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.zip !== undefined ? " has-error" : "")}
                        id="zip"
                        {...register("zip", { required: false })}
                        defaultValue={""}
                      />
                      {errors && errors.zip !== undefined ? (
                        <span className={"error-message"}>{t(errors.zip?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="city" className="form-label">
                        {t("Miejscowość")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.city !== undefined ? " has-error" : "")}
                        id="city"
                        {...register("city", { required: true })}
                        defaultValue={""}
                      />
                      {errors && errors.city !== undefined ? (
                        <span className={"error-message"}>{t(errors.city?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="country" className="form-label">
                        {t("Kraj")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.country !== undefined ? " has-error" : "")}
                        id="country"
                        {...register("country", { required: false })}
                        defaultValue={""}
                      />
                      {errors && errors.country !== undefined ? (
                        <span className={"error-message"}>{t(errors.country?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="email" className="form-label">
                        {t("Email")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.email !== undefined ? " has-error" : "")}
                        id="email"
                        {...register("email", { required: false })}
                        defaultValue={""}
                      />
                      {errors && errors.email !== undefined ? (
                        <span className={"error-message"}>{t(errors.email?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="phone" className="form-label">
                        {t("Telefon")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.phone !== undefined ? " has-error" : "")}
                        id="phone"
                        {...register("phone", { required: false })}
                        defaultValue={""}
                      />
                      {errors && errors.phone !== undefined ? (
                        <span className={"error-message"}>{t(errors.phone?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="info" className="form-label">
                        {t("Dodatkowe informacje")}
                      </label>
                      <textarea
                        className={"form-control form-control-lg" + (errors && errors.info !== undefined ? " has-error" : "")}
                        id="info"
                        rows={3}
                        {...register("info", { required: false })}
                        defaultValue={""}
                      ></textarea>
                      {errors && errors.info !== undefined ? (
                        <span className={"error-message"}>{t(errors.info?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer p-4 text-muted">
                <div className="row">
                  <div className="col-12 text-end">
                    <Link className="btn btn-outline-primary ms-3" to={"/partners"}>
                      {t("Anuluj")}
                    </Link>
                    {id !== undefined && id !== null ? (
                      <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                    ) : (
                      <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
