import React, {useContext} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import DriverForm from '../../components/forms/driver-form';

export default function DriversAdd() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "drivers";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Sterowniki"), path: "/drivers"},
      {path: null, label: t("Dodaj")},
    ];
    setLayout({...layout});
  }, []);

  return <DriverForm />;
}
