import React, {useContext, useState} from 'react';
import {Order} from '../../types/order';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {FormInputDpdOrder} from '../../types/forms';
import {generate} from '../../lib/random-id-lib';
import {ToastsContext} from '../../hooks/toasts-context';
import useOrders from '../../hooks/use-orders';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {DpdOrder} from '../../types/dpd';

interface DpdOrderFormProps {
  order: Order | null;
}

interface InitialSize {
  x: number;
  y: number;
  z: number;
  weight: number;
  title: string;
}

export default function DpdOrderForm({order = null}: DpdOrderFormProps) {
  const {t} = useTranslation();
  const {createDpdOrder} = useOrders();
  const [pickupDate, setPickupDate] = useState<any>(null);
  const {toasts, setToasts} = useContext(ToastsContext);
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputDpdOrder>();
  const [working, setWorking] = useState<boolean>(false);
  const [spedLabel, setSpedLabel] = useState<string | null>(null);
  const [dpdOrder, setDpdOrder] = useState<DpdOrder | null>(null);

  const initialSizes: Array<InitialSize> = [
    {x: 8, y: 38, z: 64, weight: 25, title: t('Mała')},
    {x: 19, y: 38, z: 64, weight: 25, title: t('Średnia')},
    {x: 41, y: 38, z: 64, weight: 25, title: t('Duża')},
  ];

  const handleLogin = async (data: any) => {
    console.log('handleLogin', data);
    if (!working && spedLabel === null) {
      setWorking(true);
      try {
        const result = await createDpdOrder(order!.id!, data);
        console.log('res', result);
        if (result.spedLabel !== undefined && result.spedLabel !== null) {
          setSpedLabel(result.spedLabel);
        }
        if (result.dpdOrder !== undefined && result.dpdOrder !== null) {
          setDpdOrder(result.dpdOrder);
        }
        toasts.push({
          title: t("Sukces"),
          content: t("Kurier zamówiony."),
          show: true,
          id: generate(32),
          type: "success"
        });
        setToasts([...toasts]);
        setWorking(false);
      } catch (e: any) {
        const err = e.response.data;
        console.log(err);
        let errMsg: string = t("Nie udało się zamówić kuriera DPD.");
        if (err.status === 'error' && err.type === 'zip') {
          errMsg += ` ${t('Błędny kod pocztowy nadawcy lub odbiorcy.')}`
        } else if (err.status === 'error' && err.type === 'already_send') {
          errMsg += ` ${t('Kurier został już przypisany do zamówienia.')}`
        }
        toasts.push({
          title: t("Błąd"),
          content: errMsg,
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
        setWorking(false);
      }
    }
  }

  const changedInitialSize = (e: any) => {
    const index = parseFloat(e.target.value);
    if (index > -1) {
      const size = initialSizes[index];
      setValue('sizeX', size.x.toString());
      setValue('sizeY', size.y.toString());
      setValue('sizeZ', size.z.toString());
      setValue('weight', size.weight.toString());
    }
  }

  return (
    <>
      {order !== null ? (
        <>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div>
                  <span className={'me-3'}><b>{t('Numer zlecenia')}:</b></span>
                  <span>{order.orderNumber}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <span className={'me-3'}><b>{t('Liczba sterowników')}:</b></span>
                <span>{order.driversCounter}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <span className={'me-3'}><b>{t('Nadawca')}:</b></span>
                  <span>{order.PartnerSource!.title}, {order.PartnerSource!.city}, {order.PartnerSource!.street}, {order.PartnerSource!.zip}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <span className={'me-3'}><b>{t('Odbiorca')}:</b></span>
                  <span>{order.PartnerDestination!.title}, {order.PartnerDestination!.city}, {order.PartnerDestination!.street}, {order.PartnerDestination!.zip}</span>
                </div>
              </div>
            </div>
            <br />
            {dpdOrder === null ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="pickupDate" className="form-label">
                        {t("Data wysyłki")}
                      </label>
                      <DatePicker
                        className={"form-control form-control-lg" + (errors && errors.pickupDate !== undefined ? " has-error" : "")}
                        selected={pickupDate}
                        {...register('pickupDate', {required: true})}
                        onChange={(date: any) => {
                          setPickupDate(date);
                          setValue('pickupDate', moment(date).format('YYYY-MM-DD'));
                        }}
                        id="pickupDate"
                        locale="pl"
                        dateFormat="P"
                      />
                      {errors && errors.pickupDate !== undefined ? (
                        <span className={"error-message"}>{t(errors.pickupDate?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="pickupTimeFrom" className="form-label">
                        {t("Godzina wysyłki (od)")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.pickupTimeFrom !== undefined ? " has-error" : "")}
                        id="pickupTimeFrom"
                        {...register("pickupTimeFrom", {required: true})}
                        defaultValue={"09:00"}
                      />
                      {errors && errors.pickupTimeFrom !== undefined ? (
                        <span className={"error-message"}>{t(errors.pickupTimeFrom?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="pickupTimeTo" className="form-label">
                        {t("Godzina wysyłki (do)")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.pickupTimeTo !== undefined ? " has-error" : "")}
                        id="pickupTimeTo"
                        {...register("pickupTimeTo", {required: true})}
                        defaultValue={"17:00"}
                      />
                      {errors && errors.pickupTimeTo !== undefined ? (
                        <span className={"error-message"}>{t(errors.pickupTimeTo?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="initialSize" className="form-label">
                        {t("Wybierz rozmiar lub wpisz nowe rozmiary")}
                      </label>
                      <select
                        className={"form-control form-control-lg" + (errors && errors.initialSize !== undefined ? " has-error" : "")}
                        id="initialSize"
                        {...register("initialSize", {required: true})}
                        defaultValue={-1}
                        onChange={changedInitialSize}
                      >
                        <option value={-1}>{t('Rozmiar własny')}</option>
                        {initialSizes.map((size, sIndex) => (
                          <option value={sIndex} key={`initialSize${sIndex + 1}`}>
                            {size.title} {size.x}x{size.y}x{size.z}cm {size.weight}kg
                          </option>
                        ))}
                      </select>
                      {errors && errors.initialSize !== undefined ? (
                        <span className={"error-message"}>{t(errors.initialSize?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="sizeX" className="form-label">
                        {t("Rozmiar X")} ({t('Szerokość')})
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.sizeX !== undefined ? " has-error" : "")}
                        id="sizeX"
                        {...register("sizeX", {required: true})}
                        defaultValue={""}
                      />
                      {errors && errors.sizeX !== undefined ? (
                        <span className={"error-message"}>{t(errors.sizeX?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="sizeY" className="form-label">
                        {t("Rozmiar Y")} ({t('Wysokość')})
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.sizeY !== undefined ? " has-error" : "")}
                        id="sizeY"
                        {...register("sizeY", {required: true})}
                        defaultValue={""}
                      />
                      {errors && errors.sizeY !== undefined ? (
                        <span className={"error-message"}>{t(errors.sizeY?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="sizeZ" className="form-label">
                        {t("Rozmiar Z")} ({t('Długość')})
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.sizeZ !== undefined ? " has-error" : "")}
                        id="sizeZ"
                        {...register("sizeZ", {required: true})}
                        defaultValue={""}
                      />
                      {errors && errors.sizeZ !== undefined ? (
                        <span className={"error-message"}>{t(errors.sizeZ?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="weight" className="form-label">
                        {t("Waga")}
                      </label>
                      <input
                        className={"form-control form-control-lg" + (errors && errors.weight !== undefined ? " has-error" : "")}
                        id="weight"
                        {...register("weight", {required: true})}
                        defaultValue={""}
                      />
                      {errors && errors.weight !== undefined ? (
                        <span className={"error-message"}>{t(errors.weight?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className={'text-center'}>
                  <button type={'submit'} className={'btn btn-primary btn-lg'}>
                    {working ? (
                      <img src={'/svg/loader.svg'} className={'button-loader'} />
                    ) : <></>}
                    {!working ? t('Zamów kuriera DPD') : t('Proszę czekać...')}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-sm-12">
                    <span className={'me-3'}><b>{t('Numer zamówienia DPD')}:</b></span>
                    <span className={'text-success'}>{dpdOrder.dpdOrderId}</span>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-sm-12 text-center">
                    <a className={'btn btn-primary btn-lg'} href={spedLabel!} target={'_blank'}>
                      {t('Pobierz etykietę')}
                    </a>
                  </div>
                </div>
              </>
            )}
          </form>
        </>
      ) : <></>}
    </>
  )
}
