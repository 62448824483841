import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastsContext } from "../../hooks/toasts-context";
import { generate } from "../../lib/random-id-lib";
import { CarType } from "../../types/car";
import useCarType from "../../hooks/use-car-type";
import { FormInputCarType } from "../../types/forms";

interface CarTypeFormProps {
  carType?: CarType | null;
  id?: string | null;
}

export default function CarTypeForm({ id = null, carType = null }: CarTypeFormProps) {
  const { t } = useTranslation();
  const { createCarType, updateCarType } = useCarType();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputCarType>();
  const { toasts, setToasts } = useContext(ToastsContext);

  React.useEffect(() => {
    if (carType !== undefined && carType !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputCarType);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputCarType, carType[k as keyof CarType]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    if (id !== undefined && id !== null) {
      try {
        await updateCarType(id, data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        navigate("/settings/car/type");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createCarType(data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        navigate("/settings/car/type");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj rodzaj pojazdu" : "Dodaj rodzaj pojazdu")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Nazwa")}
                    </label>
                    <input
                      className={"form-control form-control-lg" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", { required: true })}
                      defaultValue={""}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary ms-3" to={"/settings/car/type"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary ms-3">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary ms-3">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
