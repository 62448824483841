import React, {useContext} from "react";
import {LayoutContext} from "../../hooks/layout-context";
import {useTranslation} from "react-i18next";
import PartnerForm from '../../components/forms/partner-form';

export default function PartnerAdd() {
  const {t} = useTranslation();
  const {layout, setLayout} = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "partners";
    layout.showSearch = false;
    layout.breadcrumbs = [
      {label: t("Oddziały"), path: "/partners"},
      {path: null, label: t("Dodaj")},
    ];
    setLayout({...layout});
  }, []);

  return <PartnerForm />;
}
