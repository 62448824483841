import React, { useContext, useState } from "react";
import { LayoutContext } from "../../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CarTypeForm from "../../../components/forms/car-type-form";
import { CarType } from "../../../types/car";
import useCarType from "../../../hooks/use-car-type";

export default function CarTypeEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useCarType();
  const [carType, setCarType] = useState<CarType | null>();

  React.useEffect(() => {
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setCarType(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      {
        label: t("Rodzaj pojazdu"),
        path: "/settings/car/type",
      },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return carType !== undefined && carType !== null ? <CarTypeForm id={params.id || null} carType={carType} /> : <></>;
}
